import * as THREE from 'three'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useControls } from 'leva'

export function VideoPlane({ videoConfig }) {
  // Reference to the video element
  const videoRef = useRef(null)
  // Video texture
  const [videoTexture, setVideoTexture] = useState(null)
  // Create a ref to store the mesh for direct scale updates
  const meshRef = useRef(null)
  
  // Get animation config to access startScale directly, with fallback values
  const defaultStartScale = 0.5; // Match the value in InteractionsManager
  
  // Access the Video Animation controls directly
  const animationControls = useControls('Video Animation', {}, { disabled: true }) 
  
  // Safely extract the scale value with fallbacks
  const safeStartScale = useMemo(() => {
    // Analyze what we're getting from animationControls
    console.log('Animation controls in VideoPlane:', JSON.stringify(animationControls));
    
    // Try to get startScale from animation controls
    if (animationControls && typeof animationControls.startScale === 'number' && !isNaN(animationControls.startScale)) {
      console.log('Using animation startScale:', animationControls.startScale);
      return animationControls.startScale;
    }
    
    // Last resort fallback
    console.log('Using default scale:', defaultStartScale);
    return defaultStartScale;
  }, [animationControls]);
  
  // Effect to update the scale whenever relevant values change
  useEffect(() => {
    if (meshRef.current) {
      console.log('Updating video plane scale to:', safeStartScale);
      meshRef.current.scale.set(safeStartScale, safeStartScale, safeStartScale);
    }
  }, [safeStartScale]);
  
  // Load and setup video texture
  useEffect(() => {
    // Create video element
    const video = document.createElement('video')
    video.src = '/11.mp4'
    video.crossOrigin = 'Anonymous'
    video.loop = true
    video.muted = true
    video.playsInline = true
    
    // Create texture from video
    const texture = new THREE.VideoTexture(video)
    texture.minFilter = THREE.LinearFilter
    texture.magFilter = THREE.LinearFilter
    texture.format = THREE.RGBAFormat
    
    // Store references
    videoRef.current = video
    setVideoTexture(texture)
    
    // Play video
    video.play().catch(err => console.error("Error playing video:", err))
    
    return () => {
      // Cleanup
      video.pause()
      video.remove()
      texture.dispose()
    }
  }, [])
  
  // Add resize event handling to ensure the video plane is properly visible
  useEffect(() => {
    const handleResize = () => {
      console.log('VideoPlane handling resize event')
      // Ensure visibility is correctly set from config
      if (meshRef.current) {
        meshRef.current.visible = videoConfig.visible
        // Reset position and scale to original values if needed
        meshRef.current.scale.set(safeStartScale, safeStartScale, safeStartScale)
      }
    }
    
    window.addEventListener('resize', handleResize)
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [videoConfig.visible, safeStartScale])
  
  // Log scale info for debugging on each render
  console.log('VideoPlane scale info:', {
    actualScale: safeStartScale,
    animationControls: animationControls?.startScale,
    defaultScale: defaultStartScale
  });
  
  // Only render if enabled and texture is loaded
  if (!videoConfig.enabled || !videoTexture) return null
  
  return (
    <mesh 
      ref={meshRef}
      position={[0, videoConfig.yPosition, 0]} 
      rotation={[-Math.PI / 2, 0, 0]}
      userData={{ 
        isVideoPlane: true, 
        name: "VideoPlane",
        clickable: videoConfig.clickable 
      }}
      name="VideoPlane"
      scale={[safeStartScale, safeStartScale, safeStartScale]} 
      renderOrder={1000} // Ensure it renders on top
      visible={videoConfig.visible}
    >
      <planeGeometry args={[videoConfig.width / 4, videoConfig.height / 4]} />
      <meshBasicMaterial 
        map={videoTexture} 
        transparent={true} 
        opacity={videoConfig.opacity} 
        side={THREE.DoubleSide}
      />
    </mesh>
  )
} 