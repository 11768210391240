import { useState, useEffect } from 'react';

// Detect if the current device is a mobile device
export function isMobileDevice() {
  if (typeof navigator === 'undefined') return false;
  
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    // Include tablet detection
    /iPad|Android|Touch/i.test(navigator.userAgent) ||
    // Include alternative approach for iOS detection
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    // Check window width for smaller screens (typical for mobile devices)
    (typeof window !== 'undefined' && window.innerWidth < 768);
}

// Get optimized settings based on device type
export function getOptimizedSettings() {
  const isMobile = isMobileDevice();
  
  return {
    // Lower samples for mobile to improve performance
    samples: isMobile ? 2 : 4,
    // Lower resolution for mobile
    resolution: isMobile ? 512 : 1024,
    // Other optimization settings
    mobileBrowser: isMobile,
    // Polygon reduction for geometry
    geometryDetail: isMobile ? 0.5 : 1,
    // Shadow quality
    shadowMapSize: isMobile ? 512 : 2048,
    // Lower shadow frames
    shadowFrames: isMobile ? 50 : 200,
  };
}

// React hook for responsive device detection with performance settings
export function useDeviceOptimization() {
  const [deviceSettings, setDeviceSettings] = useState(() => getOptimizedSettings());
  const [screenSize, setScreenSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0
  });
  
  useEffect(() => {
    const handleResize = () => {
      // Update screen size
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      
      // Update device settings based on new screen size
      setDeviceSettings(getOptimizedSettings());
    };
    
    // Set initial values
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  
  return {
    ...deviceSettings,
    screenSize,
    aspectRatio: screenSize.width / screenSize.height
  };
} 