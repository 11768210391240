// Better noise function with more irregularity
export function sinCosNoise(x, y, z, freq = { x: 1, y: 1, z: 1 }, amplitude = 0.5) {
  // Use different frequencies for each dimension to create more organic patterns
  return Math.sin(x * freq.x * 2.1) * Math.cos(y * freq.y * 3.2) * Math.sin(z * freq.z * 1.3) * amplitude
    + Math.sin(x * freq.x * 1.2 + y * freq.y * 1.7) * Math.cos(z * freq.z * 2.3) * (amplitude * 0.6)
    + Math.cos(x * freq.x * 0.9 + z * freq.z * 1.2) * Math.sin(y * freq.y * 2.7) * (amplitude * 0.4);
}

// Perlin-like noise function
export function perlinNoise(x, y, z, freq = { x: 1, y: 1, z: 1 }, amplitude = 0.5) {
  const dot = (a, b) => a.x * b.x + a.y * b.y + a.z * b.z;
  
  // Hash function to generate pseudo-random values
  const hash = (i, j, k) => {
    const h = (i * 73856093) ^ (j * 19349663) ^ (k * 83492791);
    return Math.sin(h * 0.0127) * 0.5 + 0.5;
  };
  
  // Get grid cell coordinates
  const xi = Math.floor(x * freq.x);
  const yi = Math.floor(y * freq.y);
  const zi = Math.floor(z * freq.z);
  
  // Get position within cell
  const xf = x * freq.x - xi;
  const yf = y * freq.y - yi;
  const zf = z * freq.z - zi;
  
  // Smooth interpolation function
  const fade = t => t * t * t * (t * (t * 6 - 15) + 10);
  
  // Interpolate between corner values
  const result = hash(xi, yi, zi) * xf * yf * zf
               + hash(xi+1, yi, zi) * (1-xf) * yf * zf
               + hash(xi, yi+1, zi) * xf * (1-yf) * zf
               + hash(xi, yi, zi+1) * xf * yf * (1-zf)
               + hash(xi+1, yi+1, zi) * (1-xf) * (1-yf) * zf
               + hash(xi+1, yi, zi+1) * (1-xf) * yf * (1-zf)
               + hash(xi, yi+1, zi+1) * xf * (1-yf) * (1-zf)
               + hash(xi+1, yi+1, zi+1) * (1-xf) * (1-yf) * (1-zf);
               
  return (result * 2 - 1) * amplitude;
}

// Cellular/Voronoi noise
export function cellularNoise(x, y, z, freq = { x: 1, y: 1, z: 1 }, amplitude = 0.5) {
  // Scale the input coordinates
  x *= freq.x;
  y *= freq.y;
  z *= freq.z;
  
  // Calculate cell coordinates
  const xi = Math.floor(x);
  const yi = Math.floor(y);
  const zi = Math.floor(z);
  
  let minDist = 1.0;
  
  // Check cells in a 3x3x3 neighborhood
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      for (let k = -1; k <= 1; k++) {
        // Feature point offset (pseudo-random)
        const offset = {
          x: 0.5 + 0.5 * Math.sin(xi + i * 12.9898 + (yi + j) * 78.233 + (zi + k) * 43.2791),
          y: 0.5 + 0.5 * Math.sin(xi + i * 39.346 + (yi + j) * 11.798 + (zi + k) * 39.347),
          z: 0.5 + 0.5 * Math.sin(xi + i * 73.156 + (yi + j) * 18.292 + (zi + k) * 14.191)
        };
        
        // Calculate distance to feature point
        const dx = x - (xi + i + offset.x);
        const dy = y - (yi + j + offset.y);
        const dz = z - (zi + k + offset.z);
        const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);
        
        minDist = Math.min(minDist, dist);
      }
    }
  }
  
  return (1.0 - minDist) * amplitude;
}

// Ridged noise function for sharp ridges
export function ridgedNoise(x, y, z, freq = { x: 1, y: 1, z: 1 }, amplitude = 0.5) {
  const n = sinCosNoise(x, y, z, freq, 1.0);
  return (1.0 - Math.abs(n)) * amplitude;
}

// Generate fractal Brownian motion (fBm) by combining multiple noise layers
export function fractalNoise(x, y, z, noiseConfig) {
  // Base properties
  const baseFreq = { 
    x: noiseConfig.freqX, 
    y: noiseConfig.freqY, 
    z: noiseConfig.freqZ 
  };
  const baseAmplitude = noiseConfig.amplitude;
  const noiseType = noiseConfig.type;
  
  // Get the number of layers (octaves) to combine
  const layers = noiseConfig.noiseLayers || 1;
  const lacunarity = 2.0; // Frequency multiplier between octaves
  const persistence = noiseConfig.layerInfluence || 0.5; // Amplitude multiplier between octaves
  
  let total = 0;
  let frequency = 1.0;
  let amplitude = 1.0;
  let maxValue = 0;  // Used for normalizing the result
  
  // Add successive layers of noise
  for (let i = 0; i < layers; i++) {
    // Calculate the frequency for this layer
    const layerFreq = {
      x: baseFreq.x * frequency,
      y: baseFreq.y * frequency,
      z: baseFreq.z * frequency
    };
    
    // Get noise value based on the selected noise type
    let noiseValue;
    switch (noiseType) {
      case 'sinCos':
        noiseValue = sinCosNoise(x, y, z, layerFreq, amplitude);
        break;
      case 'perlin':
        noiseValue = perlinNoise(x, y, z, layerFreq, amplitude);
        break;
      case 'cellular':
        noiseValue = cellularNoise(x, y, z, layerFreq, amplitude);
        break;
      case 'ridged':
        noiseValue = ridgedNoise(x, y, z, layerFreq, amplitude);
        break;
      default:
        noiseValue = perlinNoise(x, y, z, layerFreq, amplitude);
    }
    
    total += noiseValue;
    maxValue += amplitude;
    
    // Prepare for the next octave
    frequency *= lacunarity;
    amplitude *= persistence;
  }
  
  // Normalize the result to keep it within the desired amplitude range
  return (total / maxValue) * baseAmplitude;
}

// Apply noise function based on selected type with support for multi-layered noise
export function applyNoise(x, y, z, noiseConfig) {
  // Check if we need multi-layered noise (fBm)
  if (noiseConfig.noiseLayers && noiseConfig.noiseLayers > 1) {
    return fractalNoise(x, y, z, noiseConfig);
  }
  
  // Otherwise, use single-layer noise as before
  const freq = { x: noiseConfig.freqX, y: noiseConfig.freqY, z: noiseConfig.freqZ };
  
  switch (noiseConfig.type) {
    case 'sinCos':
      return sinCosNoise(x, y, z, freq, noiseConfig.amplitude);
    case 'perlin':
      return perlinNoise(x, y, z, freq, noiseConfig.amplitude);
    case 'cellular':
      return cellularNoise(x, y, z, freq, noiseConfig.amplitude);
    case 'ridged':
      return ridgedNoise(x, y, z, freq, noiseConfig.amplitude);
    default:
      return perlinNoise(x, y, z, freq, noiseConfig.amplitude);
  }
} 