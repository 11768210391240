import { useControls, folder } from 'leva'
import { useMemo } from 'react'

// Text controls hook that returns the text configuration
export function useTextControls(textType = 'video') {
  if (textType === 'skills') {
    return useControls('Skills Text', {
      text: { value: 'SKILLS' },
      size: { value: 0.5, min: 0.1, max: 10, step: 0.1 },
      height: { value: 1.2, min: 0.1, max: 2, step: 0.1 },
      bevelEnabled: { value: true },
      bevelThickness: { value: 0.03, min: 0, max: 0.5, step: 0.01 },
      bevelSize: { value: 0.02, min: 0, max: 0.5, step: 0.01 },
      bevelSegments: { value: 3, min: 1, max: 8, step: 1 },
      bevelColor: { value: '#4287f5' },
      color: { value: '#000000' },
      metalness: { value: 0, min: 0, max: 1, step: 0.01 },
      roughness: { value: 0, min: 0, max: 1, step: 0.01 },
      outline: folder({
        enabled: { value: true },
        outlineColor: { value: '#ffffff' },
        thickness: { value: 0.5, min: 0.01, max: 0.5, step: 0.01 }
      }, { collapsed: true })
    }, { collapsed: true })
  } else if (textType === 'myself') {
    return useControls('Myself Text', {
      text: { value: 'MYSELF' },
      size: { value: 0.5, min: 0.1, max: 10, step: 0.1 },
      height: { value: 1.2, min: 0.1, max: 2, step: 0.1 },
      bevelEnabled: { value: true },
      bevelThickness: { value: 0.03, min: 0, max: 0.5, step: 0.01 },
      bevelSize: { value: 0.02, min: 0, max: 0.5, step: 0.01 },
      bevelSegments: { value: 3, min: 1, max: 8, step: 1 },
      bevelColor: { value: '#4287f5' },
      color: { value: '#000000' },
      metalness: { value: 0, min: 0, max: 1, step: 0.01 },
      roughness: { value: 0, min: 0, max: 1, step: 0.01 },
      outline: folder({
        enabled: { value: true },
        outlineColor: { value: '#ffffff' },
        thickness: { value: 0.5, min: 0.01, max: 0.5, step: 0.01 }
      }, { collapsed: true })
    }, { collapsed: true })
  } else if (textType === 'linktree') {
    return useControls('Linktree Text', {
      text: { value: 'LINKTREE' },
      size: { value: 0.5, min: 0.1, max: 10, step: 0.1 },
      height: { value: 1.2, min: 0.1, max: 2, step: 0.1 },
      bevelEnabled: { value: true },
      bevelThickness: { value: 0.03, min: 0, max: 0.5, step: 0.01 },
      bevelSize: { value: 0.02, min: 0, max: 0.5, step: 0.01 },
      bevelSegments: { value: 3, min: 1, max: 8, step: 1 },
      bevelColor: { value: '#4287f5' },
      color: { value: '#000000' },
      metalness: { value: 0, min: 0, max: 1, step: 0.01 },
      roughness: { value: 0, min: 0, max: 1, step: 0.01 },
      outline: folder({
        enabled: { value: true },
        outlineColor: { value: '#ffffff' },
        thickness: { value: 0.5, min: 0.01, max: 0.5, step: 0.01 }
      }, { collapsed: true })
    }, { collapsed: true })
  } else {
    // Default 'video' text
    return useControls('Video Text', {
      text: { value: 'VIDEO' },
      size: { value: 0.5, min: 0.1, max: 10, step: 0.1 },
      height: { value: 1.2, min: 0.1, max: 2, step: 0.1 },
      bevelEnabled: { value: true },
      bevelThickness: { value: 0.03, min: 0, max: 0.5, step: 0.01 },
      bevelSize: { value: 0.02, min: 0, max: 0.5, step: 0.01 },
      bevelSegments: { value: 3, min: 1, max: 8, step: 1 },
      bevelColor: { value: '#4287f5' },
      color: { value: '#000000' },
      metalness: { value: 0, min: 0, max: 1, step: 0.01 },
      roughness: { value: 0, min: 0, max: 1, step: 0.01 },
      outline: folder({
        enabled: { value: true },
        outlineColor: { value: '#ffffff' },
        thickness: { value: 0.5, min: 0.01, max: 0.5, step: 0.01 }
      }, { collapsed: true })
    }, { collapsed: true })
  }
}

// Hook to calculate text dimensions based on plate dimensions
export function useScaledTextDimensions(dimensions, textConfig) {
  // Check if mobile/portrait mode
  const isMobile = typeof window !== 'undefined' && window.innerWidth < window.innerHeight
  
  // Calculate text size proportional to plate dimensions
  const scaledTextSize = useMemo(() => {
    // Scale text size based on the average of width and depth
    const averageDimension = (dimensions.width + dimensions.depth) / 2;
    const baseSize = textConfig.size;
    
    // Make text LARGER on mobile (1.5x larger than default)
    const mobileFactor = isMobile ? 1.3 : 1.0;
    
    // Scale text proportionally but with a maximum cap
    return Math.min(baseSize * (averageDimension / 20) * mobileFactor, baseSize * 4);
  }, [dimensions.width, dimensions.depth, textConfig.size, isMobile]);
  
  const scaledTextHeight = useMemo(() => {
    // Scale text height proportionally to text size
    // Use same mobileFactor for consistent scaling
    const mobileFactor = isMobile ? 0.8 : 1.0;
    return textConfig.height * (scaledTextSize / textConfig.size) * mobileFactor;
  }, [textConfig.height, scaledTextSize, textConfig.size, isMobile]);

  return { scaledTextSize, scaledTextHeight };
} 