import * as THREE from 'three'
import { applyNoise } from './noiseUtils'

// Function to create a deformed box geometry
export function createDeformedBoxGeometry(width, height, depth, subdivisionsWidth, subdivisionsHeight, subdivisionsDepth, noiseConfig) {
  // Increase subdivisions for more detailed deformation
  const geometry = new THREE.BoxGeometry(
    width,
    height,
    depth,
    subdivisionsWidth,
    subdivisionsHeight,
    subdivisionsDepth
  )
  
  const positionAttribute = geometry.attributes.position
  const positions = positionAttribute.array
  
  // Apply noise to each vertex
  for (let i = 0; i < positions.length; i += 3) {
    const x = positions[i]
    const y = positions[i + 1]
    const z = positions[i + 2]
    
    // Calculate how far from the edge (0 = at edge, 1 = center)
    const edgeFactorX = 1.0 - Math.min(1, Math.abs(Math.abs(x) - width/2) * 4 / width)
    const edgeFactorZ = 1.0 - Math.min(1, Math.abs(Math.abs(z) - depth/2) * 4 / depth)
    const edgeFactor = Math.min(edgeFactorX, edgeFactorZ)
    
    // Apply deformation based on noise configuration
    const noiseValue = applyNoise(
      x * noiseConfig.scale, 
      y * noiseConfig.scale, 
      z * noiseConfig.scale, 
      noiseConfig
    )
    
    positions[i] += noiseValue * edgeFactor * width * 0.1 * noiseConfig.xInfluence
    positions[i + 1] += noiseValue * 0.5 * height * 0.4 * noiseConfig.yInfluence
    positions[i + 2] += noiseValue * edgeFactor * depth * 0.1 * noiseConfig.zInfluence
  }
  
  geometry.computeVertexNormals()
  return geometry
} 