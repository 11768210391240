import * as THREE from 'three'
import { useThree, useFrame } from '@react-three/fiber'
import { MeshTransmissionMaterial, useTexture } from '@react-three/drei'
import { useMemo, useEffect, useState, useRef } from 'react'
import { createDeformedBoxGeometry } from '../utils/geometryUtils'
import { Text3DComponent } from './Text3DComponent'
import { VideoPlane } from './VideoPlane'
import { useAllControls } from '../controls/allControls'

export function GlassBoxes(props) {
  const { viewport } = useThree()
  // Set fixed dimensions instead of changing based on window size
  const [dimensions] = useState({ width: 20, height: 0.5, depth: 20 })
  const [texturesLoaded, setTexturesLoaded] = useState(false)
  // Create refs for both plates
  const bottomPlateRef = useRef()
  const topPlateRef = useRef()
  // Bottom plate has fixed position
  const bottomPlatePosition = [0, 3, 0]
  // Top plate has initial position but will be moved with scroll
  const topPlatePosition = useRef([0, 1, 0])
  // Track scroll amount
  const scrollOffset = useRef(0)
  
  // Get device settings from props or use defaults
  const deviceSettings = props.deviceSettings || { 
    samples: 6, 
    resolution: 1024,
    mobileBrowser: false
  }
  
  // Import all controls from the centralized controls file
  const {
    materialConfig,
    videoConfig,
    scratchesConfig,
    topPlateConfig,
    bottomPlateConfig,
    scrollControls,
    bottomScrollControls
  } = useAllControls(deviceSettings)
  
  // Load scratch textures
  // Use try-catch to handle texture loading
  const [scratchTextures, setTextures] = useState({})
  
  useEffect(() => {
    // Create texture loader
    const loader = new THREE.TextureLoader()
    const textures = {}
    
    // Load normal map
    loader.load(
      '/ScratchesLight001_NRM_3K.jpg',
      (texture) => {
        textures.normalMap = texture
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        
        // Load overlay 1
        loader.load(
          '/ScratchesLight001_OVERLAY_VAR1_3K.jpg',
          (texture) => {
            textures.overlay1 = texture
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping
            
            // Load overlay 2
            loader.load(
              '/ScratchesLight001_OVERLAY_VAR2_3K.jpg',
              (texture) => {
                textures.overlay2 = texture
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping
                setTextures(textures)
                setTexturesLoaded(true)
              },
              undefined,
              (err) => console.error('Error loading overlay 2:', err)
            )
          },
          undefined,
          (err) => console.error('Error loading overlay 1:', err)
        )
      },
      undefined,
      (err) => console.error('Error loading normal map:', err)
    )
  }, [])
  
  // Notify parent component of fixed dimensions if callback provided
  useEffect(() => {
    if (props.onSizeChange) {
      props.onSizeChange(dimensions)
    }
  }, [dimensions, props.onSizeChange])
  
  // Track scroll target and current position
  const scrollTarget = useRef(0)
  const bottomScrollTarget = useRef(0)
  const bottomScrollOffset = useRef(0)
  
  // Track touch events for mobile scrolling
  const touchStartY = useRef(0)
  const touchStartX = useRef(0)
  const lastTouchY = useRef(0)
  const lastTouchX = useRef(0)
  const isTouching = useRef(false)
  
  // Handle wheel events for scrolling
  useEffect(() => {
    const handleScroll = (event) => {
      // Update top plate scroll target if enabled
      if (scrollControls.scrollEnabled) {
        // Normalize wheel delta across browsers
        const delta = event.deltaY || -event.wheelDelta || event.detail
        const normalized = delta !== 0 ? Math.sign(delta) * 0.5 : 0
        
        // Update the target scroll position
        scrollTarget.current += normalized * scrollControls.scrollOffsetMultiplier
      }
      
      // Update bottom plate scroll target if enabled
      if (bottomScrollControls.scrollEnabled) {
        // Normalize wheel delta across browsers
        const delta = event.deltaY || -event.wheelDelta || event.detail
        const normalized = delta !== 0 ? Math.sign(delta) * 0.5 : 0
        
        // Apply inversion if enabled
        const direction = bottomScrollControls.invertDirection ? -1 : 1
        
        // If linked with top plate, use the same target, otherwise calculate independently
        if (bottomScrollControls.linkWithTop) {
          bottomScrollTarget.current = scrollTarget.current * (bottomScrollControls.scrollOffsetMultiplier / scrollControls.scrollOffsetMultiplier) * direction
        } else {
          // Update the target scroll position
          bottomScrollTarget.current += normalized * bottomScrollControls.scrollOffsetMultiplier * direction
        }
      }
    }
    
    // Touch event handlers for mobile scrolling
    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        isTouching.current = true
        touchStartY.current = event.touches[0].clientY
        touchStartX.current = event.touches[0].clientX
        lastTouchY.current = event.touches[0].clientY
        lastTouchX.current = event.touches[0].clientX
      }
    }
    
    const handleTouchMove = (event) => {
      if (!isTouching.current || event.touches.length !== 1) return
      
      // Calculate touch delta since last move
      const touchY = event.touches[0].clientY
      const touchX = event.touches[0].clientX
      const deltaY = lastTouchY.current - touchY
      const deltaX = lastTouchX.current - touchX
      
      // Update reference for next move
      lastTouchY.current = touchY
      lastTouchX.current = touchX
      
      // Only respond to horizontal movements - ignore vertical swipes completely
      const isHorizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY)
      
      // If it's not a horizontal swipe, don't do anything
      if (!isHorizontalSwipe) return
      
      // Only use the horizontal delta
      const touchSensitivity = 0.05
      const normalizedX = deltaX * touchSensitivity
      
      // Apply increased sensitivity for mobile devices
      const mobileFactor = deviceSettings.mobileBrowser ? 1.5 : 1.0
      
      // Update top plate scroll target if enabled
      if (scrollControls.scrollEnabled) {
        // Only apply horizontal scrolling
        scrollTarget.current += normalizedX * scrollControls.scrollOffsetMultiplier * mobileFactor
      }
      
      // Update bottom plate scroll target if enabled
      if (bottomScrollControls.scrollEnabled) {
        // Apply inversion if enabled
        const direction = bottomScrollControls.invertDirection ? -1 : 1
        
        // If linked with top plate, use the same target, otherwise calculate independently
        if (bottomScrollControls.linkWithTop) {
          bottomScrollTarget.current = scrollTarget.current * (bottomScrollControls.scrollOffsetMultiplier / scrollControls.scrollOffsetMultiplier) * direction
        } else {
          // Only apply horizontal scrolling
          bottomScrollTarget.current += normalizedX * bottomScrollControls.scrollOffsetMultiplier * direction * mobileFactor
        }
      }
      
      // Prevent default to avoid page scrolling
      event.preventDefault()
    }
    
    const handleTouchEnd = () => {
      isTouching.current = false
    }
    
    // Add event listeners
    window.addEventListener('wheel', handleScroll, { passive: true })
    window.addEventListener('touchstart', handleTouchStart, { passive: true })
    window.addEventListener('touchmove', handleTouchMove, { passive: false }) // non-passive to allow preventDefault
    window.addEventListener('touchend', handleTouchEnd, { passive: true })
    window.addEventListener('touchcancel', handleTouchEnd, { passive: true })
    
    // Cleanup
    return () => {
      window.removeEventListener('wheel', handleScroll)
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
      window.removeEventListener('touchcancel', handleTouchEnd)
    }
  }, [
    scrollControls.scrollEnabled, 
    scrollControls.scrollOffsetMultiplier,
    bottomScrollControls.scrollEnabled,
    bottomScrollControls.scrollOffsetMultiplier,
    bottomScrollControls.invertDirection,
    bottomScrollControls.linkWithTop,
    deviceSettings.mobileBrowser
  ])
  
  // Update the top plate position based on scroll with smooth damping
  useFrame((state, delta) => {
    if (!topPlateRef.current || !scrollControls.scrollEnabled) return
    
    // Apply damping to the scroll movement
    // Smoothly interpolate between current position and target position
    const damping = scrollControls.scrollDamping;
    const smoothness = scrollControls.smoothness;
    
    // Use lerp (linear interpolation) for smooth movement
    scrollOffset.current = THREE.MathUtils.lerp(
      scrollOffset.current,
      scrollTarget.current,
      (1 - smoothness) * (60 * delta) // Adjust based on delta for frame-rate independence
    )
    
    // Calculate the new position based on scroll direction
    if (scrollControls.scrollDirection === 'horizontal') {
      // Changed from X to Z axis for horizontal movement
      topPlatePosition.current = [0, 1, scrollOffset.current]
    } else {
      // Changed from Z to X axis for vertical movement
      topPlatePosition.current = [scrollOffset.current, 1, 0]
    }
    
    // Update the top plate position
    topPlateRef.current.position.set(...topPlatePosition.current)
  })
  
  // Configure normal map based on controls
  useEffect(() => {
    if (!texturesLoaded) return
    
    if (scratchTextures.normalMap) {
      scratchTextures.normalMap.repeat.set(scratchesConfig.textureScale, scratchesConfig.textureScale)
    }
    if (scratchTextures.overlay1) {
      scratchTextures.overlay1.repeat.set(scratchesConfig.textureScale, scratchesConfig.textureScale)
      // Adjust intensity for overlay1
      scratchTextures.overlay1.intensity = scratchesConfig.roughnessMapIntensity
    }
    if (scratchTextures.overlay2) {
      scratchTextures.overlay2.repeat.set(scratchesConfig.textureScale, scratchesConfig.textureScale)
      // Adjust intensity for overlay2
      scratchTextures.overlay2.intensity = scratchesConfig.roughnessMapIntensity
    }
  }, [scratchTextures, scratchesConfig.textureScale, scratchesConfig.roughnessMapIntensity, texturesLoaded])
  
  // Animation time reference
  const animationTime = useRef(0)
  const noiseOffset = useRef({ x: 0, y: 0, z: 0 })

  // Top plate animated noise controls - derived from user controls but with animated offsets
  const animatedNoiseParams = useRef({
    type: 'perlin',
    amplitude: 2.0,
    scale: 2.4,
    freqX: 1.0,
    freqY: 1.0,
    freqZ: 1.0,
    xInfluence: 1.0,
    yInfluence: 1.0,
    zInfluence: 1.0
  })
  
  // Animate the top plate by updating its position and rotation slightly
  useFrame((state, delta) => {
    if (!topPlateRef.current || !topPlateConfig.enableAnimation) return
    
    // Update animation time
    animationTime.current += delta * topPlateConfig.animationSpeed
    
    // Create more detailed oscillating movements with multiple frequencies
    const t = animationTime.current;
    const intensity = topPlateConfig.animationIntensity * 0.05; // Reduce base intensity for more subtle movements
    
    // Use multiple sine waves with different frequencies for more complex, natural motion
    const offsetX = (
      Math.sin(t * 0.3) * 0.6 + 
      Math.sin(t * 1.7) * 0.3 + 
      Math.sin(t * 4.2) * 0.1
    ) * intensity;
    
    const offsetY = (
      Math.cos(t * 0.5) * 0.5 + 
      Math.cos(t * 2.3) * 0.3 + 
      Math.cos(t * 5.1) * 0.2
    ) * intensity * 0.5; // Y movement is half as strong
    
    const offsetZ = (
      Math.sin(t * 0.4) * 0.7 + 
      Math.sin(t * 1.9) * 0.2 + 
      Math.sin(t * 4.8) * 0.1
    ) * intensity;
    
    // Apply subtle rotations with multiple frequencies for more detailed movement
    const rotX = (
      Math.sin(t * 0.2) * 0.7 + 
      Math.sin(t * 1.3) * 0.3
    ) * 0.005 * topPlateConfig.animationIntensity;
    
    const rotY = (
      Math.cos(t * 0.3) * 0.6 + 
      Math.cos(t * 1.5) * 0.4
    ) * 0.005 * topPlateConfig.animationIntensity;
    
    const rotZ = (
      Math.sin(t * 0.25) * 0.8 + 
      Math.sin(t * 1.1) * 0.2
    ) * 0.005 * topPlateConfig.animationIntensity;
    
    // Apply movement and rotation to the plate
    if (scrollControls.scrollDirection === 'horizontal') {
      // For horizontal scrolling, keep the Z from scroll and animate X,Y
      topPlateRef.current.position.set(
        offsetX, 
        1 + offsetY, 
        scrollOffset.current + offsetZ
      )
    } else {
      // For vertical scrolling, keep the X from scroll and animate Y,Z
      topPlateRef.current.position.set(
        scrollOffset.current + offsetX, 
        1 + offsetY, 
        offsetZ
      )
    }
    
    // Apply subtle rotation
    topPlateRef.current.rotation.set(rotX, rotY, rotZ)
    
    // Accumulate noise offsets over time using direct parameters
    // Use different frequencies and smaller increments for more detailed changes
    const detailFactor = topPlateConfig.noiseAnimationDetail; // Use detail parameter to control micro-movements
    noiseOffset.current.x += delta * topPlateConfig.noiseOffsetX * topPlateConfig.animationSpeed * 0.1 * detailFactor;
    noiseOffset.current.y += delta * topPlateConfig.noiseOffsetY * topPlateConfig.animationSpeed * 0.1 * detailFactor;
    noiseOffset.current.z += delta * topPlateConfig.noiseOffsetZ * topPlateConfig.animationSpeed * 0.1 * detailFactor;
    
    // Determine when to update geometry based on morphInterval (converted to frames)
    const morphIntervalFrames = Math.floor(60 * topPlateConfig.morphInterval);
    
    // Update only if we're at the right frame and delta isn't too large (avoiding laggy updates)
    if (Math.floor(state.clock.elapsedTime * 60) % morphIntervalFrames === 0 && state.clock.delta < 0.1) {
      // Create advanced noise configuration with multi-layered noise
      const noiseParams = {
        type: topPlateConfig.noiseType,
        amplitude: topPlateConfig.amplitude,
        scale: topPlateConfig.scale,
        freqX: topPlateConfig.freqX + Math.sin(t * 0.5) * 0.05 * detailFactor + noiseOffset.current.x,
        freqY: topPlateConfig.freqY + Math.cos(t * 0.7) * 0.05 * detailFactor + noiseOffset.current.y,
        freqZ: topPlateConfig.freqZ + Math.sin(t * 0.3) * 0.05 * detailFactor + noiseOffset.current.z,
        xInfluence: topPlateConfig.xInfluence,
        yInfluence: topPlateConfig.yInfluence,
        zInfluence: topPlateConfig.zInfluence,
        // Add multi-layered noise parameters
        noiseLayers: topPlateConfig.noiseLayers,
        layerInfluence: topPlateConfig.layerInfluence,
        noiseDetail: topPlateConfig.noiseAnimationDetail
      };
      
      // Create a new geometry with multi-layered noise
      const newGeometry = createDeformedBoxGeometry(
        topPlateConfig.width, topPlateConfig.height, topPlateConfig.depth, 
        topPlateConfig.resolution, 12, topPlateConfig.resolution,
        noiseParams
      )
      
      // Update the geometry
      topPlateRef.current.geometry.dispose()
      topPlateRef.current.geometry = newGeometry
    }
  })
  
  // Animation references for bottom plate
  const bottomAnimationTime = useRef(0)
  const bottomNoiseOffset = useRef({ x: 0, y: 0, z: 0 })

  // Animate the bottom plate
  useFrame((state, delta) => {
    if (!bottomPlateRef.current) return
    
    // Apply scrolling with smooth damping if enabled
    if (bottomScrollControls.scrollEnabled) {
      // Smoothly interpolate between current position and target position
      const smoothness = bottomScrollControls.smoothness;
      
      // Use lerp (linear interpolation) for smooth movement
      bottomScrollOffset.current = THREE.MathUtils.lerp(
        bottomScrollOffset.current,
        bottomScrollTarget.current,
        (1 - smoothness) * (60 * delta) // Adjust based on delta for frame-rate independence
      )
    }
    
    // Skip the rest of animation if animation is disabled
    if (!bottomPlateConfig.enableAnimation && !bottomScrollControls.scrollEnabled) return
    
    // Update animation time with phase offset
    bottomAnimationTime.current += delta * bottomPlateConfig.animationSpeed
    
    // Get the phase offset and time
    const phase = bottomPlateConfig.animationPhase
    const t = bottomAnimationTime.current + phase
    const intensity = bottomPlateConfig.animationIntensity * 0.05; // Reduce base intensity
    
    // Create more detailed oscillating movements with multiple frequencies
    const offsetX = (
      Math.sin(t * 0.3) * 0.6 + 
      Math.sin(t * 2.1) * 0.3 + 
      Math.sin(t * 4.7) * 0.1
    ) * intensity;
    
    const offsetY = (
      Math.cos(t * 0.4) * 0.5 + 
      Math.cos(t * 1.8) * 0.3 + 
      Math.cos(t * 5.3) * 0.2
    ) * intensity * 0.8; // Y movement slightly subdued
    
    const offsetZ = (
      Math.sin(t * 0.35) * 0.7 + 
      Math.sin(t * 2.2) * 0.2 + 
      Math.sin(t * 5.1) * 0.1
    ) * intensity;
    
    // Apply subtle rotations with multiple frequencies
    const rotX = (
      Math.sin(t * 0.25) * 0.7 + 
      Math.sin(t * 1.6) * 0.3
    ) * 0.008 * bottomPlateConfig.animationIntensity;
    
    const rotY = (
      Math.cos(t * 0.3) * 0.6 + 
      Math.cos(t * 1.4) * 0.4
    ) * 0.008 * bottomPlateConfig.animationIntensity;
    
    const rotZ = (
      Math.sin(t * 0.2) * 0.8 + 
      Math.sin(t * 1.3) * 0.2
    ) * 0.008 * bottomPlateConfig.animationIntensity;
    
    // Apply movement and rotation to the bottom plate, incorporating scroll
    let posX = offsetX;
    let posY = 3 + offsetY;
    let posZ = offsetZ;
    
    // Add scroll offset based on direction
    if (bottomScrollControls.scrollEnabled) {
      if (bottomScrollControls.scrollDirection === 'horizontal') {
        // Horizontal scrolling affects Z
        posZ += bottomScrollOffset.current;
      } else {
        // Vertical scrolling affects X
        posX += bottomScrollOffset.current;
      }
    }
    
    // Apply the final position
    bottomPlateRef.current.position.set(posX, posY, posZ);
    
    // Apply subtle rotation
    bottomPlateRef.current.rotation.set(rotX, rotY, rotZ)
    
    // Accumulate noise offsets over time with more detailed fluctuations
    const detailFactor = bottomPlateConfig.noiseAnimationDetail;
    bottomNoiseOffset.current.x += delta * bottomPlateConfig.noiseOffsetX * bottomPlateConfig.animationSpeed * 0.1 * detailFactor;
    bottomNoiseOffset.current.y += delta * bottomPlateConfig.noiseOffsetY * bottomPlateConfig.animationSpeed * 0.1 * detailFactor;
    bottomNoiseOffset.current.z += delta * bottomPlateConfig.noiseOffsetZ * bottomPlateConfig.animationSpeed * 0.1 * detailFactor;
    
    // Determine when to update geometry based on morphInterval (converted to frames)
    const morphIntervalFrames = Math.floor(60 * bottomPlateConfig.morphInterval);
    
    // Update only if we're at the right frame and delta isn't too large (avoiding laggy updates)
    if (Math.floor(state.clock.elapsedTime * 60) % morphIntervalFrames === 0 && state.clock.delta < 0.1) {
      // Create advanced noise configuration with multi-layered noise and micro-fluctuations
      const noiseParams = {
        type: bottomPlateConfig.noiseType,
        amplitude: bottomPlateConfig.amplitude,
        scale: bottomPlateConfig.scale,
        freqX: bottomPlateConfig.freqX + Math.sin(t * 0.6) * 0.05 * detailFactor + bottomNoiseOffset.current.x,
        freqY: bottomPlateConfig.freqY + Math.cos(t * 0.9) * 0.05 * detailFactor + bottomNoiseOffset.current.y,
        freqZ: bottomPlateConfig.freqZ + Math.sin(t * 0.4) * 0.05 * detailFactor + bottomNoiseOffset.current.z,
        xInfluence: bottomPlateConfig.xInfluence,
        yInfluence: bottomPlateConfig.yInfluence,
        zInfluence: bottomPlateConfig.zInfluence,
        // Add multi-layered noise parameters
        noiseLayers: bottomPlateConfig.noiseLayers,
        layerInfluence: bottomPlateConfig.layerInfluence,
        noiseDetail: bottomPlateConfig.noiseAnimationDetail
      };
      
      // Create a new geometry with enhanced noise parameters
      const newGeometry = createDeformedBoxGeometry(
        bottomPlateConfig.width, bottomPlateConfig.height, bottomPlateConfig.depth, 
        bottomPlateConfig.resolution, 12, bottomPlateConfig.resolution,
        noiseParams
      )
      
      // Update the geometry
      bottomPlateRef.current.geometry.dispose()
      bottomPlateRef.current.geometry = newGeometry
    }
  })
  
  // Create deformed geometries with enhanced noise controls
  const topBoxGeometry = useMemo(() => 
    createDeformedBoxGeometry(
      topPlateConfig.width, topPlateConfig.height, topPlateConfig.depth, 
      topPlateConfig.resolution, 12, topPlateConfig.resolution, 
      {
        type: topPlateConfig.noiseType,
        amplitude: topPlateConfig.amplitude,
        scale: topPlateConfig.scale,
        freqX: topPlateConfig.freqX,
        freqY: topPlateConfig.freqY,
        freqZ: topPlateConfig.freqZ,
        xInfluence: topPlateConfig.xInfluence,
        yInfluence: topPlateConfig.yInfluence,
        zInfluence: topPlateConfig.zInfluence,
        noiseLayers: topPlateConfig.noiseLayers,
        layerInfluence: topPlateConfig.layerInfluence,
        noiseDetail: topPlateConfig.noiseAnimationDetail
      }
    ),
    [
      topPlateConfig.width,
      topPlateConfig.height,
      topPlateConfig.depth,
      topPlateConfig.resolution,
      topPlateConfig.noiseType,
      topPlateConfig.amplitude,
      topPlateConfig.scale,
      topPlateConfig.freqX,
      topPlateConfig.freqY,
      topPlateConfig.freqZ,
      topPlateConfig.xInfluence,
      topPlateConfig.yInfluence,
      topPlateConfig.zInfluence,
      topPlateConfig.noiseLayers,
      topPlateConfig.layerInfluence,
      topPlateConfig.noiseAnimationDetail
    ]
  )
  
  const bottomBoxGeometry = useMemo(() => 
    createDeformedBoxGeometry(
      bottomPlateConfig.width, bottomPlateConfig.height, bottomPlateConfig.depth, 
      bottomPlateConfig.resolution, 12, bottomPlateConfig.resolution, 
      {
        type: bottomPlateConfig.noiseType,
        amplitude: bottomPlateConfig.amplitude,
        scale: bottomPlateConfig.scale,
        freqX: bottomPlateConfig.freqX,
        freqY: bottomPlateConfig.freqY,
        freqZ: bottomPlateConfig.freqZ,
        xInfluence: bottomPlateConfig.xInfluence,
        yInfluence: bottomPlateConfig.yInfluence,
        zInfluence: bottomPlateConfig.zInfluence,
        noiseLayers: bottomPlateConfig.noiseLayers,
        layerInfluence: bottomPlateConfig.layerInfluence,
        noiseDetail: bottomPlateConfig.noiseAnimationDetail
      }
    ),
    [
      bottomPlateConfig.width,
      bottomPlateConfig.height,
      bottomPlateConfig.depth,
      bottomPlateConfig.resolution,
      bottomPlateConfig.noiseType,
      bottomPlateConfig.amplitude,
      bottomPlateConfig.scale,
      bottomPlateConfig.freqX,
      bottomPlateConfig.freqY,
      bottomPlateConfig.freqZ,
      bottomPlateConfig.xInfluence,
      bottomPlateConfig.yInfluence,
      bottomPlateConfig.zInfluence,
      bottomPlateConfig.noiseLayers,
      bottomPlateConfig.layerInfluence,
      bottomPlateConfig.noiseAnimationDetail
    ]
  )
  
  // Get the current roughness map based on selection
  const getRoughnessMap = () => {
    if (!texturesLoaded) return null
    
    if (!scratchesConfig.roughnessMapEnabled) return null
    
    return scratchesConfig.roughnessMapVariant === 'variant1' 
      ? scratchTextures.overlay1 
      : scratchTextures.overlay2
  }
  
  // Get the clearcoat normal map (using whichever overlay variant is not used for roughness)
  const getClearcoatNormalMap = () => {
    if (!texturesLoaded || !scratchesConfig.clearcoatMapEnabled) return null
    
    return scratchesConfig.roughnessMapVariant === 'variant1' 
      ? scratchTextures.overlay2 
      : scratchTextures.overlay1
  }

  // Edge positioning configuration
  const textPositionConfig = {
    leftEdgeOffset: 2,   // Distance from left edge
    rightEdgeOffset: 4,  // Increased offset to move right text more to the left
    heightPosition: 2.3, // Y position of all texts
    frontZ: -4,          // Z position for front texts
    backZ: 4,            // Z position for back texts
    // Mobile positioning offsets
    mobileTopOffset: 5,  // Y position for top text on mobile
    mobileSpacing: 2.5   // Vertical spacing between texts on mobile
  }

  // Create refs for dynamic edge positions with immediate initialization
  const leftEdgeX = useRef(0)
  const rightEdgeX = useRef(0)
  
  // Text position refs for mobile layout
  const videoPosition = useRef([0, 0, 0])
  const skillsPosition = useRef([0, 0, 0])
  const myselfPosition = useRef([0, 0, 0])
  const linktreePosition = useRef([0, 0, 0])
  
  // Is mobile device
  const isMobile = deviceSettings.mobileBrowser || (viewport.width / viewport.height < 1)
  
  // Simple direct function to calculate positions
  const updateTextPositions = () => {
    // Direct calculation based on current viewport
    leftEdgeX.current = -viewport.width / 2 + textPositionConfig.leftEdgeOffset
    rightEdgeX.current = viewport.width / 2 - textPositionConfig.rightEdgeOffset
    
    // Calculate positions differently for mobile (portrait) vs desktop (landscape)
    if (isMobile) {
      // Center X position for all texts on mobile
      const centerX = 0  // You can adjust this if you want to shift all text left/right
      
      // Stack the larger texts with increased spacing
      videoPosition.current = [centerX - 1, 2.3, -2.8]        // VIDEO text - furthest back
      skillsPosition.current = [centerX - 1, 2.3, -1.1]       // SKILLS text - further back
      myselfPosition.current = [centerX - 1, 2.3, 0.5]       // MYSELF text - closer
      linktreePosition.current = [centerX - 1.6, 2.3, 2.2]      // LINKTREE text - closest
    } else {
      // Desktop layout - texts in corners
      videoPosition.current = [leftEdgeX.current, textPositionConfig.heightPosition, textPositionConfig.frontZ]
      skillsPosition.current = [rightEdgeX.current, textPositionConfig.heightPosition, textPositionConfig.frontZ]
      myselfPosition.current = [leftEdgeX.current, textPositionConfig.heightPosition, textPositionConfig.backZ]
      linktreePosition.current = [rightEdgeX.current, textPositionConfig.heightPosition, textPositionConfig.backZ]
    }
    
    console.log(`Text positions updated. Mobile: ${isMobile}, Viewport: ${viewport.width.toFixed(2)}x${viewport.height.toFixed(2)}`)
  }
  
  // Initialize on component mount - simple and direct
  useEffect(() => {
    // Initial update
    updateTextPositions()
    
    // Add event listener for resize
    window.addEventListener('resize', updateTextPositions)
    
    // Add event listeners for additional load events
    window.addEventListener('load', updateTextPositions)
    document.addEventListener('DOMContentLoaded', updateTextPositions)
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', updateTextPositions)
      window.removeEventListener('load', updateTextPositions)
      document.removeEventListener('DOMContentLoaded', updateTextPositions)
    }
  }, [viewport.width, viewport.height, isMobile, deviceSettings.mobileBrowser])
  
  // Also update on every frame during the first second
  const startTime = useRef(Date.now())
  
  // Update positions on each frame for the first second
  useFrame(() => {
    // Calculate elapsed time since component mounted
    const elapsedMs = Date.now() - startTime.current
    
    // Update positions on every frame for the first second
    if (elapsedMs < 1000) {
      updateTextPositions()
    }
  })

  return (
    <>
      {/* Bottom box with fixed position */}
      <mesh 
        ref={bottomPlateRef}
        position={bottomPlatePosition} 
        castShadow 
        receiveShadow 
        geometry={bottomBoxGeometry}
      >
        <MeshTransmissionMaterial 
          background={new THREE.Color(materialConfig.bg)} 
          {...materialConfig}
          normalMap={texturesLoaded && scratchesConfig.enableNormalMap ? scratchTextures.normalMap : null}
          normalScale={new THREE.Vector2(scratchesConfig.normalMapIntensity, scratchesConfig.normalMapIntensity)} 
          roughnessMap={getRoughnessMap()}
          roughness={materialConfig.roughness * (scratchesConfig.roughnessMapEnabled ? scratchesConfig.roughnessMapIntensity : 1)}
          clearcoatNormalMap={getClearcoatNormalMap()}
          clearcoatNormalScale={new THREE.Vector2(scratchesConfig.clearcoatMapIntensity, scratchesConfig.clearcoatMapIntensity)}
        />
      </mesh>
      
      {/* Video plane now imported as separate component */}
      <VideoPlane videoConfig={videoConfig} />
      
      {/* 3D Texts between plates */}
      {/* VIDEO text on left side */}
      <Text3DComponent 
        dimensions={dimensions} 
        deviceSettings={deviceSettings} 
        textType="video" 
        position={videoPosition.current}
      />
      
      {/* SKILLS text on right side */}
      <Text3DComponent 
        dimensions={dimensions} 
        deviceSettings={deviceSettings} 
        textType="skills" 
        position={skillsPosition.current}
      />
      
      {/* MYSELF text on bottom-left */}
      <Text3DComponent 
        dimensions={dimensions} 
        deviceSettings={deviceSettings} 
        textType="myself" 
        position={myselfPosition.current}
      />
      
      {/* LINKTREE text on bottom-right */}
      <Text3DComponent 
        dimensions={dimensions} 
        deviceSettings={deviceSettings} 
        textType="linktree" 
        position={linktreePosition.current}
      />
      
      {/* Top box with scroll-based position */}
      <mesh 
        ref={topPlateRef}
        position={topPlatePosition.current} 
        castShadow 
        receiveShadow 
        geometry={topBoxGeometry}
      >
        <MeshTransmissionMaterial 
          background={new THREE.Color(materialConfig.bg)} 
          {...materialConfig}
          normalMap={texturesLoaded && scratchesConfig.enableNormalMap ? scratchTextures.normalMap : null}
          normalScale={new THREE.Vector2(scratchesConfig.normalMapIntensity, scratchesConfig.normalMapIntensity)}
          roughnessMap={getRoughnessMap()}
          roughness={materialConfig.roughness * (scratchesConfig.roughnessMapEnabled ? scratchesConfig.roughnessMapIntensity : 1)}
          clearcoatNormalMap={getClearcoatNormalMap()}
          clearcoatNormalScale={new THREE.Vector2(scratchesConfig.clearcoatMapIntensity, scratchesConfig.clearcoatMapIntensity)}
        />
      </mesh>
    </>
  )
} 