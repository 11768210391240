import { Text3D } from '@react-three/drei'
import { useTextControls, useScaledTextDimensions } from '../utils/textUtils'
import { useThree, useFrame } from '@react-three/fiber'
import { useRef, useState, useEffect } from 'react'
import * as THREE from 'three'

export function Text3DComponent({ dimensions, deviceSettings = {}, textType = 'video', position }) {
  // Get text configuration from the utility
  const textConfig = useTextControls(textType)
  
  // Get scaled text dimensions from the utility
  const { scaledTextSize, scaledTextHeight } = useScaledTextDimensions(dimensions, textConfig)

  // Set mobile optimized settings if available
  const isMobile = deviceSettings.mobileBrowser || false
  const curveSegments = isMobile ? 6 : 12
  
  // Add refs and state for responsive positioning
  const textRef = useRef()
  const materialRef = useRef()
  const outlineRef = useRef()
  const { viewport, size } = useThree()
  const [textPosition, setTextPosition] = useState(position || [-6, 2.3, -4])
  
  // States for hover and click animations
  const [hovered, setHovered] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [animationProgress, setAnimationProgress] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const [targetColor, setTargetColor] = useState(new THREE.Color(textConfig.color))
  const [currentColor, setCurrentColor] = useState(new THREE.Color(textConfig.color))
  
  // Determine if outline should be rendered and get outline properties
  const showOutline = textConfig.outline && textConfig.outline.enabled
  const outlineColor = showOutline ? textConfig.outline.outlineColor : '#ffffff'
  const outlineThickness = showOutline && textConfig.outline.thickness ? textConfig.outline.thickness : 0.05
  
  // Reset animation on new click
  const handleClick = () => {
    setClicked(true)
    setAnimationProgress(0)
    setIsAnimating(true)
    
    // Toggle planes when text is clicked
    if (textType === 'video' && typeof window.toggleVideoPlanes === 'function') {
      console.log('Video text clicked, toggling video planes')
      window.toggleVideoPlanes()
    } else if (textType === 'skills' && typeof window.toggleSkillsPlanes === 'function') {
      console.log('Skills text clicked, toggling skills planes')
      window.toggleSkillsPlanes()
    } else if (textType === 'myself' && typeof window.toggleMyselfPlanes === 'function') {
      console.log('Myself text clicked, toggling myself planes')
      window.toggleMyselfPlanes()
    } else if (textType === 'linktree' && typeof window.toggleLinktreePlanes === 'function') {
      console.log('Linktree text clicked, toggling linktree planes')
      window.toggleLinktreePlanes()
    } else {
      console.warn(`${textType} functionality not defined or toggle function not available`)
    }
  }
  
  // Update position when viewport changes or when position prop changes
  useEffect(() => {
    if (position) {
      setTextPosition(position)
    } else {
      // Calculate position based on viewport aspect ratio
      const aspectRatio = size.width / size.height
      // Adjust x-position based on aspect ratio
      const xPos = aspectRatio < 1 ? -4 : -6 * aspectRatio / 1.5
      setTextPosition([xPos, 2.3, -4])
    }
  }, [size.width, size.height, position])
  
  // For mobile, ensure text is more prominent by bringing it forward
  useEffect(() => {
    if (textRef.current && (deviceSettings.mobileBrowser || viewport.width / viewport.height < 1)) {
      // Bring text forward on mobile
      textRef.current.renderOrder = 10
    }
  }, [deviceSettings.mobileBrowser, viewport.width, viewport.height])
  
  // Color animation based on hover and click states
  useFrame((state, delta) => {
    if (textRef.current) {
      // Only apply automatic positioning if no custom position is provided
      if (!position) {
        // Smoothly adjust text position to keep it in view
        const aspectRatio = viewport.width / viewport.height
        const targetX = aspectRatio < 1 ? -4 : -6 * aspectRatio / 1.5
        textRef.current.position.x = THREE.MathUtils.lerp(
          textRef.current.position.x,
          targetX,
          0.1
        )
      }
    }
    
    // Handle color animation
    if (materialRef.current) {
      // Define colors
      const blackColor = new THREE.Color(textConfig.color)
      const blueColor = new THREE.Color("#030034")
      const whiteColor = new THREE.Color("#ffffff")
      
      // Handle animations
      if (isAnimating) {
        // Click animation
        setAnimationProgress(prevProgress => {
          // Fast to white (0.3s), faster to black (1s)
          const newProgress = prevProgress + delta * (prevProgress < 0.3 ? 3.33 : 1.0); 
          if (newProgress > 1) {
            setIsAnimating(false);
            setClicked(false);
            return 1; // Stop at 1 instead of looping back to 0
          }
          return newProgress;
        });
        
        if (animationProgress <= 0.3) {
          // First phase: black/blue to white in 0.3 seconds
          const t = animationProgress / 0.3;
          materialRef.current.color.lerpColors(
            hovered ? blueColor : blackColor,
            whiteColor,
            t
          );
        } else {
          // Second phase: white to black in 1 second
          const t = (animationProgress - 0.3) / 0.7;
          materialRef.current.color.lerpColors(
            whiteColor,
            blackColor,
            t
          );
          
          // End animation
          if (animationProgress >= 1) {
            setIsAnimating(false);
            setClicked(false);
          }
        }
      } else if (hovered) {
        // Hover animation: black to blue
        materialRef.current.color.lerp(blueColor, 0.1);
      } else if (!isAnimating) {
        // Return to black when not hovered and not in click animation
        materialRef.current.color.lerp(blackColor, 0.1);
      }
    }
  })

  // Get viewable size and determine if mobile
  const isMobileView = deviceSettings.mobileBrowser || viewport.width / viewport.height < 1

  return (
    <group 
      ref={textRef} 
      position={textPosition} 
      rotation={[-Math.PI / 2, 0, 0]}
    >
      {/* Outline text - slightly larger */}
      {showOutline && (
        <Text3D
          font="/fonts/helvetiker_regular.typeface.json"
          size={scaledTextSize + outlineThickness * 2}
          height={scaledTextHeight}
          bevelEnabled={textConfig.bevelEnabled}
          bevelThickness={textConfig.bevelThickness}
          bevelSize={textConfig.bevelSize}
          bevelSegments={isMobile ? 2 : textConfig.bevelSegments}
          curveSegments={curveSegments}
          castShadow
          receiveShadow
          center
        >
          {textConfig.text}
          <meshBasicMaterial 
            ref={outlineRef}
            color={outlineColor}
            toneMapped={false}
          />
        </Text3D>
      )}
      
      {/* Main text */}
      <Text3D
        font="/fonts/helvetiker_regular.typeface.json"
        size={scaledTextSize}
        height={scaledTextHeight}
        bevelEnabled={textConfig.bevelEnabled}
        bevelThickness={textConfig.bevelThickness}
        bevelSize={textConfig.bevelSize}
        bevelSegments={isMobile ? 2 : textConfig.bevelSegments}
        curveSegments={curveSegments}
        castShadow
        receiveShadow
        center
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={handleClick}
      >
        {textConfig.text}
        <meshStandardMaterial 
          ref={materialRef}
          color={textConfig.color}
          metalness={textConfig.metalness}
          roughness={textConfig.roughness}
        />
      </Text3D>
    </group>
  )
} 