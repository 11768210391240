import { useRef, useEffect, useState, useMemo } from 'react'
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { gsap } from 'gsap'
import { useControls, folder } from 'leva' // Import Leva for easy position controls

export function InteractionsManager() {
  const { scene, camera, gl, raycaster, mouse } = useThree()
  const [activeVideoPlane, setActiveVideoPlane] = useState(null)
  const [videoPlanesEnabled, setVideoPlanesEnabled] = useState(true) // Track if video planes are enabled
  const [skillsPlanesEnabled, setSkillsPlanesEnabled] = useState(true) // Track if skills planes are enabled
  const [myselfPlanesEnabled, setMyselfPlanesEnabled] = useState(true) // Track if myself planes are enabled
  const [linktreePlanesEnabled, setLinktreePlanesEnabled] = useState(true) // Track if linktree planes are enabled
  const originalPosition = useRef(new THREE.Vector3())
  const originalRotation = useRef(new THREE.Euler())
  const originalQuaternion = useRef(new THREE.Quaternion())
  const originalScale = useRef(new THREE.Vector3(1, 1, 1)) // Add reference for original scale
  const animationInProgress = useRef(false)
  const videoPlanes = useRef([]) // Store references to all video planes
  const skillsPlanes = useRef([]) // Store references to all skills planes
  const myselfPlanes = useRef([]) // Store references to all myself planes
  const linktreePlanes = useRef([]) // Store references to all linktree planes

  // Safe default values in case Leva doesn't initialize properly
  const defaultAnimConfig = {
    duration: 2.0,
    distance: 5,
    finalPositionOffset: { x: 0, y: 0, z: 0 },
    ease: 'power2.out',
    startScale: 0.03,
    endScale: 1.0
  }

  // Easy-to-edit animation controls with Leva UI
  const animationConfig = useControls('Video Animation', {
    duration: { value: 2.0, min: 0.5, max: 5, step: 0.1, label: "Duration (seconds)" },
    distance: { value: 5, min: 1, max: 15, step: 0.5, label: "Distance from Camera" },
    startScale: { value: 0.3, min: 0.01, max: 3, step: 0.01, label: "Start Scale" },
    endScale: { value: 1.0, min: 0.1, max: 3, step: 0.1, label: "End Scale" },
    finalPositionOffset: folder({
      x: { value: 0, min: -5, max: 5, step: 0.1, label: "X Offset" },
      y: { value: 0, min: -5, max: 5, step: 0.1, label: "Y Offset" },
      z: { value: 0, min: -5, max: 5, step: 0.1, label: "Z Offset" },
    }),
    ease: { value: 'power2.out', options: ['power1.out', 'power2.out', 'power3.out', 'back.out', 'elastic.out'], label: "Easing" }
  }, { collapsed: true }) || defaultAnimConfig

  // Force set default values if any properties are undefined
  const safeConfig = useMemo(() => {
    return {
      duration: animationConfig?.duration ?? defaultAnimConfig.duration,
      distance: animationConfig?.distance ?? defaultAnimConfig.distance,
      finalPositionOffset: {
        x: animationConfig?.finalPositionOffset?.x ?? defaultAnimConfig.finalPositionOffset.x,
        y: animationConfig?.finalPositionOffset?.y ?? defaultAnimConfig.finalPositionOffset.y,
        z: animationConfig?.finalPositionOffset?.z ?? defaultAnimConfig.finalPositionOffset.z
      },
      ease: animationConfig?.ease ?? defaultAnimConfig.ease,
      startScale: animationConfig?.startScale ?? defaultAnimConfig.startScale,
      endScale: animationConfig?.endScale ?? defaultAnimConfig.endScale
    }
  }, [animationConfig])

  useEffect(() => {
    // Find and store all planes in the scene
    const collectPlanes = () => {
      const vPlanes = []
      const sPlanes = []
      const mPlanes = []
      const lPlanes = []
      scene.traverse(object => {
        if (object && object.isMesh && object.userData) {
          if (object.userData.isVideoPlane) {
            vPlanes.push(object)
          } else if (object.userData.isSkillsPlane) {
            sPlanes.push(object)
          } else if (object.userData.isMyselfPlane) {
            mPlanes.push(object)
          } else if (object.userData.isLinktreePlane) {
            lPlanes.push(object)
          }
        }
      })
      videoPlanes.current = vPlanes
      skillsPlanes.current = sPlanes
      myselfPlanes.current = mPlanes
      linktreePlanes.current = lPlanes
      console.log(`Found ${vPlanes.length} video, ${sPlanes.length} skills, ${mPlanes.length} myself, and ${lPlanes.length} linktree planes in scene`)
    }
    
    // Run once to collect initial planes
    collectPlanes()
    
    // Setup periodic check to find new planes (every 2 seconds)
    const planeCollectorInterval = setInterval(collectPlanes, 2000)
    
    // Add window resize event listener to immediately refresh planes after resize
    const handleResize = () => {
      console.log('Window resized - refreshing plane references')
      // Add a small delay to ensure components have remounted
      setTimeout(collectPlanes, 100)
    }
    
    window.addEventListener('resize', handleResize)
    
    return () => {
      clearInterval(planeCollectorInterval)
      window.removeEventListener('resize', handleResize)
    }
  }, [scene])

  // Expose the toggle functions to the window so they can be called from UI
  useEffect(() => {
    // Define the video planes toggle function
    window.toggleVideoPlanes = () => {
      console.log('Toggle video planes function called')
      console.log('Current state:', videoPlanesEnabled ? 'enabled' : 'disabled')
      
      // Skip if animation is already in progress
      if (animationInProgress.current) {
        console.log('Animation in progress, ignoring Video text click')
        return
      }
      
      // Set animation in progress
      animationInProgress.current = true
      
      // For debugging: log all found video planes
      console.log('Video planes found:', videoPlanes.current.length)
      videoPlanes.current.forEach((plane, index) => {
        console.log(`Plane ${index}:`, plane.position.toArray())
      })
      
      if (videoPlanesEnabled) {
        // Currently enabled, need to animate OUT of view
        console.log('Animating video planes out of view')
        
        // If any plane is active, animate it back first
        if (activeVideoPlane) {
          console.log('Returning active plane to original position first')
          animateBackToOriginal()
        }
        
        // For each video plane, animate it out of view
        videoPlanes.current.forEach(plane => {
          // Store original position if we don't already have it
          if (!plane.userData.originalPosition) {
            plane.userData.originalPosition = plane.position.clone()
            console.log('Storing original position:', plane.userData.originalPosition.toArray())
          }
          
          // Calculate a position off-screen (far to the left)
          const offscreenPosition = new THREE.Vector3(
            plane.position.x - 15, // Move far to the left
            plane.position.y,
            plane.position.z
          )
          
          console.log('Animating plane from', plane.position.toArray(), 'to', offscreenPosition.toArray())
          
          // Animate to off-screen position
          gsap.to(plane.position, {
            x: offscreenPosition.x,
            y: offscreenPosition.y,
            z: offscreenPosition.z,
            duration: 1.0, 
            ease: 'power2.inOut',
            onComplete: () => {
              // Disable the plane
              plane.visible = false
              console.log('Plane hidden after animation')
            }
          })
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setVideoPlanesEnabled(false)
          animationInProgress.current = false
          console.log('Video planes are now disabled')
        }, 1000)
      } else {
        // Currently disabled, need to animate back INTO view
        console.log('Animating video planes into view')
        
        // Make sure we have video planes to animate
        if (videoPlanes.current.length === 0) {
          console.log('No video planes found, refreshing plane references')
          // Force an immediate scene scan for planes
          const vPlanes = []
          scene.traverse(object => {
            if (object && object.isMesh && object.userData && object.userData.isVideoPlane) {
              vPlanes.push(object)
            }
          })
          videoPlanes.current = vPlanes
          console.log(`Found ${vPlanes.length} video planes in refresh scan`)
        }
        
        // For each video plane, animate it back into view
        videoPlanes.current.forEach(plane => {
          // Make sure it's visible first
          plane.visible = true
          console.log('Making plane visible again')
          
          // Only animate if we have stored position
          if (plane.userData.originalPosition) {
            console.log('Animating plane back to', plane.userData.originalPosition.toArray())
            
            // Animate from current position back to original
            gsap.to(plane.position, {
              x: plane.userData.originalPosition.x,
              y: plane.userData.originalPosition.y,
              z: plane.userData.originalPosition.z,
              duration: 1.0, 
              ease: 'power2.inOut',
              onComplete: () => {
                console.log('Plane returned to original position')
              }
            })
          } else {
            console.warn('No original position stored for plane', plane)
            // If no original position, use a default position
            gsap.to(plane.position, {
              x: 0,
              y: plane.position.y,
              z: plane.position.z,
              duration: 1.0,
              ease: 'power2.inOut'
            })
          }
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setVideoPlanesEnabled(true)
          animationInProgress.current = false
          console.log('Video planes are now enabled')
        }, 1000)
      }
    }
    
    // Define the skills planes toggle function
    window.toggleSkillsPlanes = () => {
      console.log('Toggle skills planes function called')
      console.log('Current state:', skillsPlanesEnabled ? 'enabled' : 'disabled')
      
      // Skip if animation is already in progress
      if (animationInProgress.current) {
        console.log('Animation in progress, ignoring Skills text click')
        return
      }
      
      // Set animation in progress
      animationInProgress.current = true
      
      // For debugging: log all found skills planes
      console.log('Skills planes found:', skillsPlanes.current.length)
      skillsPlanes.current.forEach((plane, index) => {
        console.log(`Plane ${index}:`, plane.position.toArray())
      })
      
      if (skillsPlanesEnabled) {
        // Currently enabled, need to animate OUT of view
        console.log('Animating skills planes out of view')
        
        // For each skills plane, animate it out of view
        skillsPlanes.current.forEach(plane => {
          // Store original position if we don't already have it
          if (!plane.userData.originalPosition) {
            plane.userData.originalPosition = plane.position.clone()
            console.log('Storing original position:', plane.userData.originalPosition.toArray())
          }
          
          // Calculate a position off-screen (far to the right)
          const offscreenPosition = new THREE.Vector3(
            plane.position.x + 50, // Move far to the right
            plane.position.y,
            plane.position.z
          )
          
          console.log('Animating plane from', plane.position.toArray(), 'to', offscreenPosition.toArray())
          
          // Animate to off-screen position
          gsap.to(plane.position, {
            x: offscreenPosition.x,
            y: offscreenPosition.y,
            z: offscreenPosition.z,
            duration: 1.0, // 1 second duration
            ease: 'power2.inOut',
            onComplete: () => {
              // Disable the plane
              plane.visible = false
              console.log('Skills plane hidden after animation')
            }
          })
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setSkillsPlanesEnabled(false)
          animationInProgress.current = false
          console.log('Skills planes are now disabled')
        }, 1000)
      } else {
        // Currently disabled, need to animate back INTO view
        console.log('Animating skills planes into view')
        
        // For each skills plane, animate it back into view
        skillsPlanes.current.forEach(plane => {
          // Make sure it's visible first
          plane.visible = true
          console.log('Making skills plane visible again')
          
          // Only animate if we have stored position
          if (plane.userData.originalPosition) {
            console.log('Animating plane back to', plane.userData.originalPosition.toArray())
            
            // Animate from current position back to original
            gsap.to(plane.position, {
              x: plane.userData.originalPosition.x,
              y: plane.userData.originalPosition.y,
              z: plane.userData.originalPosition.z,
              duration: 1.0, // 1 second duration
              ease: 'power2.inOut',
              onComplete: () => {
                console.log('Skills plane returned to original position')
              }
            })
          } else {
            console.warn('No original position stored for skills plane', plane)
          }
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setSkillsPlanesEnabled(true)
          animationInProgress.current = false
          console.log('Skills planes are now enabled')
        }, 1000)
      }
    }
    
    // Define the myself planes toggle function
    window.toggleMyselfPlanes = () => {
      console.log('Toggle myself planes function called')
      console.log('Current state:', myselfPlanesEnabled ? 'enabled' : 'disabled')
      
      // Skip if animation is already in progress
      if (animationInProgress.current) {
        console.log('Animation in progress, ignoring Myself text click')
        return
      }
      
      // Set animation in progress
      animationInProgress.current = true
      
      // For debugging: log all found myself planes
      console.log('Myself planes found:', myselfPlanes.current.length)
      myselfPlanes.current.forEach((plane, index) => {
        console.log(`Plane ${index}:`, plane.position.toArray())
      })
      
      if (myselfPlanesEnabled) {
        // Currently enabled, need to animate OUT of view
        console.log('Animating myself planes out of view')
        
        // For each myself plane, animate it out of view
        myselfPlanes.current.forEach(plane => {
          // Store original position if we don't already have it
          if (!plane.userData.originalPosition) {
            plane.userData.originalPosition = plane.position.clone()
            console.log('Storing original position:', plane.userData.originalPosition.toArray())
          }
          
          // Calculate a position off-screen (far to the top)
          const offscreenPosition = new THREE.Vector3(
            plane.position.x,
            plane.position.y + 50, // Move far to the top
            plane.position.z
          )
          
          console.log('Animating plane from', plane.position.toArray(), 'to', offscreenPosition.toArray())
          
          // Animate to off-screen position
          gsap.to(plane.position, {
            x: offscreenPosition.x,
            y: offscreenPosition.y,
            z: offscreenPosition.z,
            duration: 1.0, // 1 second duration
            ease: 'power2.inOut',
            onComplete: () => {
              // Disable the plane
              plane.visible = false
              console.log('Myself plane hidden after animation')
            }
          })
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setMyselfPlanesEnabled(false)
          animationInProgress.current = false
          console.log('Myself planes are now disabled')
        }, 1000)
      } else {
        // Currently disabled, need to animate back INTO view
        console.log('Animating myself planes into view')
        
        // For each myself plane, animate it back into view
        myselfPlanes.current.forEach(plane => {
          // Make sure it's visible first
          plane.visible = true
          console.log('Making myself plane visible again')
          
          // Only animate if we have stored position
          if (plane.userData.originalPosition) {
            console.log('Animating plane back to', plane.userData.originalPosition.toArray())
            
            // Animate from current position back to original
            gsap.to(plane.position, {
              x: plane.userData.originalPosition.x,
              y: plane.userData.originalPosition.y,
              z: plane.userData.originalPosition.z,
              duration: 1.0, // 1 second duration
              ease: 'power2.inOut',
              onComplete: () => {
                console.log('Myself plane returned to original position')
              }
            })
          } else {
            console.warn('No original position stored for myself plane', plane)
          }
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setMyselfPlanesEnabled(true)
          animationInProgress.current = false
          console.log('Myself planes are now enabled')
        }, 1000)
      }
    }
    
    // Define the linktree planes toggle function
    window.toggleLinktreePlanes = () => {
      console.log('Toggle linktree planes function called')
      console.log('Current state:', linktreePlanesEnabled ? 'enabled' : 'disabled')
      
      // Skip if animation is already in progress
      if (animationInProgress.current) {
        console.log('Animation in progress, ignoring Linktree text click')
        return
      }
      
      // Set animation in progress
      animationInProgress.current = true
      
      // For debugging: log all found linktree planes
      console.log('Linktree planes found:', linktreePlanes.current.length)
      linktreePlanes.current.forEach((plane, index) => {
        console.log(`Plane ${index}:`, plane.position.toArray())
      })
      
      if (linktreePlanesEnabled) {
        // Currently enabled, need to animate OUT of view
        console.log('Animating linktree planes out of view')
        
        // For each linktree plane, animate it out of view
        linktreePlanes.current.forEach(plane => {
          // Store original position if we don't already have it
          if (!plane.userData.originalPosition) {
            plane.userData.originalPosition = plane.position.clone()
            console.log('Storing original position:', plane.userData.originalPosition.toArray())
          }
          
          // Calculate a position off-screen (far to the bottom)
          const offscreenPosition = new THREE.Vector3(
            plane.position.x,
            plane.position.y - 50, // Move far to the bottom
            plane.position.z
          )
          
          console.log('Animating plane from', plane.position.toArray(), 'to', offscreenPosition.toArray())
          
          // Animate to off-screen position
          gsap.to(plane.position, {
            x: offscreenPosition.x,
            y: offscreenPosition.y,
            z: offscreenPosition.z,
            duration: 1.0, // 1 second duration
            ease: 'power2.inOut',
            onComplete: () => {
              // Disable the plane
              plane.visible = false
              console.log('Linktree plane hidden after animation')
            }
          })
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setLinktreePlanesEnabled(false)
          animationInProgress.current = false
          console.log('Linktree planes are now disabled')
        }, 1000)
      } else {
        // Currently disabled, need to animate back INTO view
        console.log('Animating linktree planes into view')
        
        // For each linktree plane, animate it back into view
        linktreePlanes.current.forEach(plane => {
          // Make sure it's visible first
          plane.visible = true
          console.log('Making linktree plane visible again')
          
          // Only animate if we have stored position
          if (plane.userData.originalPosition) {
            console.log('Animating plane back to', plane.userData.originalPosition.toArray())
            
            // Animate from current position back to original
            gsap.to(plane.position, {
              x: plane.userData.originalPosition.x,
              y: plane.userData.originalPosition.y,
              z: plane.userData.originalPosition.z,
              duration: 1.0, // 1 second duration
              ease: 'power2.inOut',
              onComplete: () => {
                console.log('Linktree plane returned to original position')
              }
            })
          } else {
            console.warn('No original position stored for linktree plane', plane)
          }
        })
        
        // Toggle the state AFTER starting the animations
        setTimeout(() => {
          setLinktreePlanesEnabled(true)
          animationInProgress.current = false
          console.log('Linktree planes are now enabled')
        }, 1000)
      }
    }
    
    return () => {
      delete window.toggleVideoPlanes
      delete window.toggleSkillsPlanes
      delete window.toggleMyselfPlanes
      delete window.toggleLinktreePlanes
    }
  }, [
    activeVideoPlane, 
    videoPlanes, 
    skillsPlanes, 
    myselfPlanes, 
    linktreePlanes, 
    safeConfig, 
    videoPlanesEnabled, 
    skillsPlanesEnabled,
    myselfPlanesEnabled,
    linktreePlanesEnabled
  ])

  useEffect(() => {
    // Creates a handler that works with Three.js pointer events
    const handleClick = (event) => {
      // Skip if video planes are disabled
      if (!videoPlanesEnabled) {
        return
      }
      
      // Check if mouse is defined before using it
      if (!mouse) {
        console.error('Mouse position is undefined')
        return
      }
      
      // Update the raycaster with current mouse position
      try {
        raycaster.setFromCamera(mouse, camera)
      } catch (err) {
        console.error('Error setting raycaster from camera:', err)
        return
      }
      
      // Find intersected objects - use true for recursive to check all nested objects
      const allObjects = []
      scene.traverse(object => {
        if (object && object.isMesh) {
          allObjects.push(object)
          // Debug display of video plane in scene
          if (object.userData && object.userData.isVideoPlane) {
            console.log('Found video plane in scene:', object.position ? object.position.toArray() : 'no position')
          }
        }
      })
      
      console.log('Total meshes found:', allObjects.length)
      // Use recursive flag true to check child objects
      const intersects = raycaster.intersectObjects(allObjects, true)
      
      console.log('Intersections:', intersects.length)
      
      // Check each intersection for a video plane
      let foundVideoPlane = false
      let videoPlane = null
      
      for (let i = 0; i < intersects.length; i++) {
        const object = intersects[i].object
        if (object && 
            object.userData && 
            object.userData.isVideoPlane && 
            (object.userData.clickable === undefined || object.userData.clickable === true)) {
          foundVideoPlane = true
          videoPlane = object
          console.log('Found video plane at intersection', i)
          break
        }
      }
      
      // First check if we clicked on the active video plane
      if (foundVideoPlane && videoPlane && activeVideoPlane === videoPlane) {
        // If it's already the active video plane, animate it back
        // Allow this even if animation is in progress
        console.log('Video plane is already active, animating back')
        
        // If animation is in progress, cancel current animations first
        if (animationInProgress.current) {
          gsap.killTweensOf(activeVideoPlane.position);
          gsap.killTweensOf(activeVideoPlane.scale);
        }
        
        animateBackToOriginal()
        return
      }
      
      // Skip other interactions if animation is already in progress
      if (animationInProgress.current) {
        console.log('Animation in progress, ignoring click')
        return
      }
      
      console.log('Click detected')
      
      // Check if we found a video plane
      if (foundVideoPlane && videoPlane) {    
        console.log('Video plane clicked!')
        
        // Store original position and rotation
        originalPosition.current.copy(videoPlane.position)
        originalRotation.current.copy(videoPlane.rotation)
        originalQuaternion.current.copy(videoPlane.quaternion)
        
        // Store original scale
        if (videoPlane.scale) {
          originalScale.current.copy(videoPlane.scale)
          console.log('Original scale stored:', originalScale.current.toArray())
        } else {
          console.warn('Video plane has no scale property')
          originalScale.current.set(safeConfig.startScale, safeConfig.startScale, safeConfig.startScale)
        }
        
        // Set as active video plane
        setActiveVideoPlane(videoPlane)
        
        // Calculate position in front of camera
        const cameraDirection = new THREE.Vector3(0, 0, -1)
        cameraDirection.applyQuaternion(camera.quaternion)
        
        const targetPosition = new THREE.Vector3()
        targetPosition.copy(camera.position).add(
          cameraDirection.multiplyScalar(safeConfig.distance)
        )
        
        // Apply custom offsets from the config - using safeConfig instead
        targetPosition.x += safeConfig.finalPositionOffset.x;
        targetPosition.y += safeConfig.finalPositionOffset.y;
        targetPosition.z += safeConfig.finalPositionOffset.z;
        
        console.log('Moving from', videoPlane.position.toArray(), 'to', targetPosition.toArray())
        
        // Calculate rotation to face camera
        const lookAtMatrix = new THREE.Matrix4()
        lookAtMatrix.lookAt(videoPlane.position, camera.position, new THREE.Vector3(0, 1, 0))
        const targetQuaternion = new THREE.Quaternion()
        targetQuaternion.setFromRotationMatrix(lookAtMatrix)
        
        // Mark animation as in progress
        animationInProgress.current = true
        
        // Animate to position in front of camera
        gsap.to(videoPlane.position, {
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          duration: safeConfig.duration,
          ease: safeConfig.ease,
          onStart: () => {
            console.log('Animation started')
          },
          onUpdate: () => {
            // Uncomment for detailed position updates
            // console.log('Position update:', videoPlane.position.toArray())
          },
          onComplete: () => {
            console.log('Position animation complete')
          }
        })
        
        // Animate scale
        const targetScale = safeConfig.endScale
        console.log(`Animating scale from ${originalScale.current.x} to ${targetScale}`)
        
        gsap.to(videoPlane.scale, {
          x: targetScale,
          y: targetScale,
          z: targetScale,
          duration: safeConfig.duration,
          ease: safeConfig.ease,
          onComplete: () => {
            console.log('Scale animation complete')
          }
        })
      } else if (activeVideoPlane) {
        // Only animate back if we clicked on something that's NOT a video plane
        // or we didn't click on anything
        if (!foundVideoPlane) {
          // If we click anywhere else and we have an active video, animate it back
          animateBackToOriginal()
        }
      }
    }
    
    // Add DOM event listener for pointer down
    const domElement = gl.domElement
    domElement.addEventListener('pointerdown', handleClick)
    
    return () => {
      domElement.removeEventListener('pointerdown', handleClick)
    }
  }, [camera, scene, raycaster, mouse, gl, activeVideoPlane, safeConfig, videoPlanesEnabled])
  
  // Helper to match GSAP easing functions for quaternion animation
  const getEasedValue = (progress, easingName) => {
    switch (easingName) {
      case 'power1.out':
        return progress; // Linear
      case 'power2.out':
        return 1 - Math.pow(1 - progress, 2);
      case 'power3.out':
        return 1 - Math.pow(1 - progress, 3);
      case 'back.out':
        const c1 = 1.70158;
        const c3 = c1 + 1;
        return 1 + c3 * Math.pow(progress - 1, 3) + c1 * Math.pow(progress - 1, 2);
      case 'elastic.out':
        const c4 = (2 * Math.PI) / 3;
        return progress === 0
          ? 0
          : progress === 1
          ? 1
          : Math.pow(2, -10 * progress) * Math.sin((progress * 10 - 0.75) * c4) + 1;
      default:
        return 1 - Math.pow(1 - progress, 2); // Default to power2.out
    }
  };
  
  const animateBackToOriginal = () => {
    if (!activeVideoPlane) return
    
    console.log('Animating back to original position')
    
    // Mark animation as in progress
    animationInProgress.current = true
    
    // Check if original position is valid
    if (!originalPosition.current || 
        originalPosition.current.x === undefined || 
        originalPosition.current.y === undefined || 
        originalPosition.current.z === undefined) {
      console.error('Invalid original position')
      animationInProgress.current = false
      setActiveVideoPlane(null)
      return
    }
    
    // Check if quaternion exists
    if (!activeVideoPlane.quaternion) {
      console.error('Active video plane quaternion is undefined')
      animationInProgress.current = false
      setActiveVideoPlane(null)
      return
    }
    
    // Check if scale property exists
    if (!activeVideoPlane.scale) {
      console.error('Active video plane scale is undefined')
    }
    
    // Animate back to original position
    try {
      gsap.to(activeVideoPlane.position, {
        x: originalPosition.current.x,
        y: originalPosition.current.y,
        z: originalPosition.current.z,
        duration: safeConfig.duration,
        ease: safeConfig.ease.replace('out', 'in'), // Use the inverse easing
        onComplete: () => {
          animationInProgress.current = false
          setActiveVideoPlane(null)
          console.log('Return animation complete')
        }
      })
      
      // Animate back to original scale
      if (activeVideoPlane.scale && originalScale.current) {
        console.log(`Animating scale back from ${activeVideoPlane.scale.x} to ${originalScale.current.x}`)
        
        // Ensure we're animating back to the startScale value from the config
        const targetScale = safeConfig.startScale
        
        gsap.to(activeVideoPlane.scale, {
          x: targetScale,
          y: targetScale,
          z: targetScale,
          duration: safeConfig.duration,
          ease: safeConfig.ease.replace('out', 'in'), // Use the inverse easing
          onComplete: () => {
            console.log('Scale return animation complete')
          }
        })
      }
    } catch (err) {
      console.error('Error starting return animation:', err)
      animationInProgress.current = false
      setActiveVideoPlane(null)
      return
    }
    
    // REMOVED: No rotation at all
    // Don't change the rotation/orientation of the video plane
  }
  
  return null
} 