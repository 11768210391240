import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import { useMemo, useRef, useEffect } from 'react'

// Camera controller that provides subtle movement based on mouse position
export function CameraController({ plateSize }) {
  const { camera, mouse, viewport } = useThree()
  const initialPosition = useMemo(() => new THREE.Vector3(0, 15, 0), [])
  const targetPosition = useRef(new THREE.Vector3(0, 15, 0))
  
  // Camera controls
  const cameraConfig = useControls('Camera', {
    height: { value: 21.3, min: 5, max: 30, step: 0.1 },
    movementStrength: { value: 1.0, min: 0, max: 5, step: 0.1 },
    movementSmoothing: { value: 0.10, min: 0.01, max: 0.5, step: 0.01 },
    tiltAmount: { value: 0.00, min: 0, max: 0.3, step: 0.01 },
    horizontalSensitivity: { value: 1.0, min: 0.1, max: 2.0, step: 0.1, label: "Horizontal Sensitivity" },
    dampeningStrength: { value: 0.1, min: 0.1, max: 1.5, step: 0.1, label: "Dampening Strength" },
  }, { collapsed: true })

  useEffect(() => {
    // Initial camera setup for top-down view
    camera.position.copy(initialPosition)
    camera.lookAt(0, 0, 0)
    camera.up.set(0, 0, -1) // This ensures "up" is correctly oriented
  }, [camera, initialPosition])
  
  // Adjust camera height based on plate size
  useEffect(() => {
    if (plateSize) {
      // Calculate a suitable camera height based on the plate dimensions
      // Take the maximum dimension and scale it by a factor
      const maxDimension = Math.max(plateSize.width, plateSize.depth)
      const idealHeight = maxDimension * 0.75
      
      // Update the target position's y component
      targetPosition.current.setY(idealHeight)
      
      // Update initial position if needed
      initialPosition.setY(idealHeight)
      camera.position.setY(idealHeight)
    }
  }, [plateSize, camera, initialPosition])
  
  useFrame(() => {
    // Calculate aspect ratio to normalize movement
    const aspectRatio = viewport.width / viewport.height
    
    // Normalize movement to be consistent regardless of aspect ratio
    // Use the smaller dimension (usually height) as the reference
    const movementScale = Math.min(viewport.width, viewport.height) / 2
    
    // Progressive dampening for extreme aspect ratios
    // As aspect ratio increases, apply exponentially stronger dampening
    // This ensures very wide screens have properly controlled movement
    let aspectRatioDampening = 1.0
    if (aspectRatio > 1.0) {
      // Apply exponential dampening curve - more aggressive as aspect ratio grows
      aspectRatioDampening = Math.pow(1 / aspectRatio, cameraConfig.dampeningStrength)
    }
    
    // Apply consistent movement scale with strength control and progressive dampening
    const mouseX = mouse.x * movementScale * cameraConfig.movementStrength * 
                  cameraConfig.horizontalSensitivity * aspectRatioDampening
    const mouseY = mouse.y * movementScale * cameraConfig.movementStrength
    
    // Keep the camera high but allow slight tilt based on mouse position
    targetPosition.current.set(
      mouseX, 
      cameraConfig.height, 
      mouseY
    )
    
    // Smoothly interpolate towards the target position
    camera.position.lerp(targetPosition.current, cameraConfig.movementSmoothing)
    
    // Adjust the look target to maintain general top-down view but with slight tilt
    const lookTarget = new THREE.Vector3(
      mouseX * cameraConfig.tiltAmount,
      0,
      mouseY * cameraConfig.tiltAmount
    )
    camera.lookAt(lookTarget)
  })
  
  return null
} 