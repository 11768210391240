import { useControls, folder } from 'leva'

export function useAllControls(deviceSettings) {
  // Apply device-specific optimizations if provided
  const samples = deviceSettings?.samples || 6
  const resolution = deviceSettings?.resolution || 1024

  // Material controls
  const materialConfig = useControls('Material', {
    meshPhysicalMaterial: false,
    transmissionSampler: false,
    backside: true,
    samples: { value: samples, min: 1, max: 32, step: 1 },
    resolution: { value: resolution, min: 256, max: 2048, step: 256 },
    transmission: { value: 1.00, min: 0, max: 1 },
    roughness: { value: 0.00, min: 0, max: 1, step: 0.01 },
    thickness: { value: 3.56, min: 0, max: 10, step: 0.01 },
    ior: { value: 1.50, min: 1, max: 5, step: 0.01 },
    chromaticAberration: { value: 0.20, min: 0, max: 1 },
    anisotropy: { value: 0.10, min: 0, max: 1, step: 0.01 },
    distortion: { value: 0.00, min: 0, max: 1, step: 0.01 },
    distortionScale: { value: 0.01, min: 0.01, max: 1, step: 0.01 },
    clearcoat: { value: 0.00, min: 0, max: 1 },
    clearcoatRoughness: { value: 0.10, min: 0, max: 1, step: 0.01 },
    attenuationDistance: { value: 5.00, min: 0, max: 10, step: 0.01 },
    attenuationColor: '#ffffff',
    bevelColor: '#ffffff',
    color: '#ffffff',
    bg: '#ffffff'
  }, { collapsed: true })

  // Text controls
  const textConfig = useControls('Text', {
    textColor: '#ffffff',
    textBevelColor: '#4287f5',
    bevelSize: { value: 0.1, min: 0, max: 0.5, step: 0.01 },
    bevelThickness: { value: 0.03, min: 0, max: 0.2, step: 0.01 },
    bevelEnabled: { value: true },
    bevelSegments: { value: 3, min: 1, max: 10, step: 1 },
  }, { collapsed: true })

  // Video plane controls
  const videoConfig = useControls('Video Plane', {
    enabled: { value: true, label: "Show Video" },
    width: { value: 16, min: 5, max: 30, step: 0.5 },
    height: { value: 9, min: 3, max: 20, step: 0.5 },
    yPosition: { value: 2, min: 1.1, max: 2.9, step: 0.1 },
    opacity: { value: 1.0, min: 0, max: 1, step: 0.05 },
    visible: { value: false, label: "Initially Visible" },
    clickable: { value: true, label: "Enable Click Interaction" }
  }, { collapsed: true })

  // Scratches texture controls
  const scratchesConfig = useControls('Scratches', {
    enableNormalMap: { value: true },
    normalMapIntensity: { value: 0.20, min: 0, max: 2, step: 0.05 },
    roughnessMapEnabled: { value: true },
    roughnessMapVariant: { value: 'variant2', options: ['variant1', 'variant2'] },
    roughnessMapIntensity: { value: 2.00, min: 0, max: 2, step: 0.05 },
    clearcoatMapEnabled: { value: true },
    clearcoatMapIntensity: { value: 0.05, min: 0, max: 2, step: 0.05 },
    textureScale: { value: 10.0, min: 0.5, max: 10, step: 0.5 },
  }, { collapsed: true })

  // Top plate controls - main parameters
  const topPlateConfig = useControls('Top Plate', {
    // Size controls
    width: { value: 30, min: 5, max: 50, step: 1, label: "Width" },
    height: { value: 0.5, min: 0.1, max: 2, step: 0.1, label: "Height" },
    depth: { value: 100, min: 5, max: 50, step: 1, label: "Depth" },
    resolution: { value: 64, min: 8, max: 128, step: 8, label: "Resolution" },
    
    // Noise controls
    noiseType: {
      value: 'perlin',
      options: ['sinCos', 'perlin', 'cellular', 'ridged']
    },
    amplitude: { value: 2.00, min: 0, max: 2, step: 0.01 },
    scale: { value: 2.4, min: 0.1, max: 5, step: 0.1 },
    freqX: { value: 1.0, min: 0.1, max: 5, step: 0.1 },
    freqY: { value: 1.0, min: 0.1, max: 5, step: 0.1 },
    freqZ: { value: 1.0, min: 0.1, max: 5, step: 0.1 },
    xInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    yInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    zInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    
    // Animation controls
    enableAnimation: { value: true, label: "Animate Noise" },
    animationSpeed: { value: 0.5, min: 0.01, max: 10.0, step: 0.01, label: "Animation Speed" },
    animationIntensity: { value: 2.37, min: 0.01, max: 10.0, step: 0.01, label: "Animation Intensity" },
    
    // Advanced noise animation parameters
    noiseAnimationDetail: { value: 2.9, min: 0.1, max: 3.0, step: 0.1, label: "Noise Detail" },
    noiseLayers: { value: 2, min: 1, max: 3, step: 1, label: "Noise Layers" },
    layerInfluence: { value: 0.1, min: 0.1, max: 1.0, step: 0.1, label: "Layer Influence" },
    
    // Noise animation offsets
    noiseOffsetSpeed: { value: 0.01, min: 0.01, max: 1.0, step: 0.01, label: "Noise Change Speed" },
    noiseOffsetX: { value: 0.10, min: 0.01, max: 1.0, step: 0.01, label: "X Noise Speed" },
    noiseOffsetY: { value: 0.15, min: 0.01, max: 1.0, step: 0.01, label: "Y Noise Speed" },
    noiseOffsetZ: { value: 0.12, min: 0.01, max: 1.0, step: 0.01, label: "Z Noise Speed" },
    morphInterval: { value: 0.5, min: 0.1, max: 5.0, step: 0.1, label: "Morphing Interval (s)" }
  }, { collapsed: true })

  // Bottom plate controls - main parameters
  const bottomPlateConfig = useControls('Bottom Plate', {
    // Size controls
    width: { value: 30, min: 5, max: 50, step: 1, label: "Width" },
    height: { value: 0.5, min: 0.1, max: 2, step: 0.1, label: "Height" },
    depth: { value: 50, min: 5, max: 50, step: 1, label: "Depth" },
    resolution: { value: 64, min: 8, max: 128, step: 8, label: "Resolution" },
    
    // Noise controls
    noiseType: {
      value: 'ridged',
      options: ['sinCos', 'perlin', 'cellular', 'ridged']
    },
    amplitude: { value: 0.60, min: 0, max: 2, step: 0.01 },
    scale: { value: 1.2, min: 0.1, max: 5, step: 0.1 },
    freqX: { value: 1.2, min: 0.1, max: 5, step: 0.1 },
    freqY: { value: 0.8, min: 0.1, max: 5, step: 0.1 },
    freqZ: { value: 1.5, min: 0.1, max: 5, step: 0.1 },
    xInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    yInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    zInfluence: { value: 1.00, min: 0, max: 2, step: 0.01 },
    
    // Animation controls
    enableAnimation: { value: true, label: "Animate Noise" },
    animationSpeed: { value: 0.20, min: 0.01, max: 10.0, step: 0.01, label: "Animation Speed" },
    animationIntensity: { value: 3.00, min: 0.01, max: 10.0, step: 0.01, label: "Animation Intensity" },
    animationPhase: { value: 0.7, min: 0, max: 2, step: 0.1, label: "Animation Phase" },
    
    // Advanced noise animation parameters
    noiseAnimationDetail: { value: 1.0, min: 0.1, max: 3.0, step: 0.1, label: "Noise Detail" },
    noiseLayers: { value: 1, min: 1, max: 3, step: 1, label: "Noise Layers" },
    layerInfluence: { value: 0.5, min: 0.1, max: 1.0, step: 0.1, label: "Layer Influence" },
    
    // Noise animation offsets
    noiseOffsetSpeed: { value: 0.15, min: 0.01, max: 1.0, step: 0.01, label: "Noise Change Speed" },
    noiseOffsetX: { value: 0.15, min: 0.01, max: 1.0, step: 0.01, label: "X Noise Speed" },
    noiseOffsetY: { value: 0.18, min: 0.01, max: 1.0, step: 0.01, label: "Y Noise Speed" },
    noiseOffsetZ: { value: 0.16, min: 0.01, max: 1.0, step: 0.01, label: "Z Noise Speed" },
    morphInterval: { value: 0.5, min: 0.1, max: 5.0, step: 0.1, label: "Morphing Interval (s)" }
  }, { collapsed: true })

  // Top plate scroll controls
  const scrollControls = useControls('Top Plate Scroll', {
    scrollEnabled: { value: true, label: "Enable Scroll Movement" },
    scrollOffsetMultiplier: { value: 1.0, min: 0.1, max: 2.0, step: 0.1, label: "Scroll Effect Strength" },
    scrollDirection: { value: 'horizontal', options: ['horizontal', 'vertical'], label: "Scroll Move Direction" },
    scrollDamping: { value: 0.1, min: 0.01, max: 0.5, step: 0.01, label: "Scroll Damping" },
    smoothness: { value: 0.9, min: 0, max: 0.99, step: 0.01, label: "Smoothness" },
  }, { collapsed: true })

  // Bottom plate scroll controls
  const bottomScrollControls = useControls('Bottom Plate Scroll', {
    scrollEnabled: { value: true, label: "Enable Scroll Movement" },
    scrollOffsetMultiplier: { value: 0.7, min: 0.1, max: 2.0, step: 0.1, label: "Scroll Effect Strength" },
    scrollDirection: { value: 'horizontal', options: ['horizontal', 'vertical'], label: "Scroll Move Direction" },
    scrollDamping: { value: 0.1, min: 0.01, max: 0.5, step: 0.01, label: "Scroll Damping" },
    smoothness: { value: 0.92, min: 0, max: 0.99, step: 0.01, label: "Smoothness" },
    invertDirection: { value: true, label: "Invert Direction" },
    linkWithTop: { value: false, label: "Link With Top Plate" },
  }, { collapsed: true })

  return {
    materialConfig,
    textConfig,
    videoConfig,
    scratchesConfig,
    topPlateConfig,
    bottomPlateConfig,
    scrollControls,
    bottomScrollControls
  }
}