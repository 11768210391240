import { Canvas } from '@react-three/fiber'
import { AccumulativeShadows, RandomizedLight, Environment, Center } from '@react-three/drei'
import { useControls } from 'leva'
import { useState } from 'react'
import { CameraController } from './components/CameraController'
import { GlassBoxes } from './components/GlassBoxes'
import { InteractionsManager } from './components/InteractionsManager'
import { DustParticles } from './components/DustParticles'
import { FPSCounter, FPSCalculator } from './utils/FPSCounter'
import { useDeviceOptimization } from './utils/deviceDetection'

export default function App() {
  // Get device optimization settings
  const deviceSettings = useDeviceOptimization()
  
  // Environment controls
  const envControls = useControls('Environment', {
    backgroundBlur: { value: 1.2, min: 0, max: 10, step: 0.1 },
    environmentBlur: { value: 0.10, min: 0, max: 1, step: 0.01 },
    environmentIntensity: { value: 2.0, min: 0, max: 5, step: 0.1 },
    envPreset: {
      value: 'dancing_hall',
      options: [
        'dancing_hall',
        'royal_esplanade',
        'venice_sunset',
        'industrial_sunset'
      ]
    }
  }, { collapsed: true })

  // State to track plate dimensions for camera
  const [plateDimensions, setPlateDimensions] = useState(null)

  // Get environment path based on preset
  const getEnvPath = () => {
    switch(envControls.envPreset) {
      case 'royal_esplanade':
        return "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/royal_esplanade_1k.hdr";
      case 'venice_sunset':
        return "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/venice_sunset_1k.hdr";
      case 'industrial_sunset':
        return "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/industrial_sunset_02_1k.hdr";
      default:
        return "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/dancing_hall_1k.hdr";
    }
  }

  return (
    <FPSCounter>
      <Canvas shadows camera={{ position: [0, 15, 0], fov: 25 }}>
        <FPSCalculator />
        <CameraController plateSize={plateDimensions} />
        <InteractionsManager />
        <ambientLight intensity={Math.PI} />
        <group position={[0, -2.5, 0]}>
          <Center top>
            <GlassBoxes 
              onSizeChange={setPlateDimensions} 
              deviceSettings={deviceSettings}
            />
          </Center>
          <group position={[0, 2, 0]}>
            <DustParticles />
          </group>
          <AccumulativeShadows 
            frames={deviceSettings.shadowFrames} 
            alphaTest={0.9} 
            color="#3ead5d" 
            colorBlend={1} 
            opacity={0.8} 
            scale={20}
          >
            <RandomizedLight radius={10} ambient={0.5} intensity={Math.PI} position={[2.5, 8, -2.5]} bias={0.001} />
          </AccumulativeShadows>
        </group>
        <Environment 
          files={getEnvPath()} 
          background 
          backgroundBlurriness={envControls.backgroundBlur}
          blur={envControls.environmentBlur}
          intensity={envControls.environmentIntensity}
        />
      </Canvas>
      
      {/* Show performance message on mobile */}
      {deviceSettings.mobileBrowser && (
        <div style={{
          position: 'fixed',
          bottom: '10px',
          left: '10px',
          backgroundColor: 'rgba(0,0,0,0.7)',
          color: 'white',
          padding: '8px',
          fontSize: '12px',
          borderRadius: '4px',
          zIndex: 1000,
          pointerEvents: 'none'
        }}>
          Mobile optimization active (samples: {deviceSettings.samples})
        </div>
      )}
    </FPSCounter>
  )
}
