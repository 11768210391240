import React, { useEffect } from 'react'
import { Box } from '@react-three/drei'

export function ParticleBoundingBox({ boundingBox, onChange, visible = true }) {
  // This component visualizes and handles position updates for the particle bounding box
  
  // Ensure boundingBox exists and has expected properties
  if (!boundingBox || !onChange || !visible) {
    console.warn("ParticleBoundingBox missing required props", { boundingBox, onChange, visible });
    return null;
  }
  
  // Log bounding box for debugging
  useEffect(() => {
    console.log("ParticleBoundingBox received:", boundingBox);
  }, [boundingBox]);
  
  // Extract dimensions and position
  const { width, height, depth, position } = boundingBox;
  
  if (!width || !height || !depth || !position) {
    console.warn("ParticleBoundingBox missing dimension or position data", boundingBox);
    return null;
  }
  
  return (
    <Box 
      args={[width, height, depth]} 
      position={[position.x, position.y, position.z]}
      wireframe={true}
    >
      <meshBasicMaterial color="white" wireframe={true} transparent={true} opacity={0.5} />
    </Box>
  );
} 