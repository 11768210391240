import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useDustParticleControls } from '../controls/dustParticleControls'
import { Text, Billboard } from '@react-three/drei'
import { ParticleBoundingBox } from './ParticleBoundingBox'
import { useFPS } from '../utils/FPSCounter'

// Helper to create pixelated text for a true low-res effect
const createPixelatedTextTexture = (text, options) => {
  // Create a canvas for rendering text with configurable resolution
  const canvas = document.createElement('canvas')
  canvas.width = options.textResolution || 256  // Use the slider value for resolution
  canvas.height = Math.floor((options.textResolution || 256) / 1.5)  // Taller canvas for multiple lines
  
  const ctx = canvas.getContext('2d')
  
  // Clear canvas with transparent background instead of black
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  
  // Set text style with configurable font size
  const fontSize = options.textFontSize || 12 // Use the slider value for font size
  ctx.font = `bold ${fontSize}px monospace`
  ctx.fillStyle = options.textColor
  ctx.textBaseline = 'top'
  
  // Draw text line by line with more spacing between lines
  const lines = text.split('\n')
  const lineHeight = fontSize + 4 // More space between lines
  
  // Make first line (FPS) bigger and bolder
  if (lines.length > 0 && lines[0].includes('FPS')) {
    ctx.font = `bold ${fontSize + 2}px monospace`
    ctx.fillStyle = '#808080' // Grey for FPS line
    ctx.fillText(lines[0], 5, 5)
    ctx.font = `${fontSize}px monospace` // Reset for other lines
    ctx.fillStyle = options.textColor
  }
  
  // Draw remaining lines
  for (let i = 1; i < lines.length; i++) {
    ctx.fillText(lines[i], 5, 5 + i * lineHeight)
  }
  
  // If low-res effect is enabled, make it pixelated
  if (options.lowResEffect) {
    // Create a temporary scaled-down canvas for pixelation
    const tempCanvas = document.createElement('canvas')
    const scale = options.textResolution < 256 ? 0.2 : (options.textResolution < 512 ? 0.3 : 0.4) // Adjust pixelation based on resolution
    tempCanvas.width = canvas.width * scale
    tempCanvas.height = canvas.height * scale
    
    // Draw the original canvas scaled down
    const tempCtx = tempCanvas.getContext('2d')
    tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height)
    tempCtx.imageSmoothingEnabled = false
    tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height)
    
    // Clear original canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    
    // Draw the small canvas back to the original size with pixelation
    ctx.imageSmoothingEnabled = false
    ctx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height)
    
    // Add scan lines for a CRT effect
    if (Math.random() > 0.5) { // Only on some texts for variety
      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)'
      for (let y = 0; y < canvas.height; y += 4) {
        ctx.fillRect(0, y, canvas.width, 1)
      }
    }
    
    // Cleanup
    tempCanvas.remove()
  }
  
  // Create a texture from the canvas
  const texture = new THREE.CanvasTexture(canvas)
  texture.needsUpdate = true
  
  return texture
}

// Component for text label attached to a particle
const ParticleText = ({ position, textOptions, particleIndex }) => {
  const billboardRef = useRef()
  const [texture, setTexture] = useState(null)
  const [currentText, setCurrentText] = useState('')
  const originalTextRef = useRef('')
  const lastUpdateTimeRef = useRef(0)
  
  // Get current FPS
  const { fps } = useFPS()
  
  // Generate text content
  const generateTextContent = useCallback(() => {
    const lines = Math.floor(Math.random() * 4) + 2 // 2-5 lines
    let result = ''
    
    // Generate random text for each line
    for (let i = 0; i < lines; i++) {
      const lineLength = Math.floor(Math.random() * 6) + 2 // 2-8 chars (keep it short for readability)
      let line = ''
      
      // Random data-like characters
      for (let j = 0; j < lineLength; j++) {
        const chars = textOptions.lowResEffect 
          ? '01234567890ABCDEFabcdef-_.|/\\[]{}()<>!@#$%^&*+=:;'
          : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.+='
        line += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      
      result += line + (i < lines - 1 ? '\n' : '')
    }
    
    return result
  }, [textOptions.lowResEffect])
  
  // Generate initial text content
  useEffect(() => {
    const text = generateTextContent()
    originalTextRef.current = text
    setCurrentText(text)
  }, [generateTextContent, textOptions.lowResEffect])
  
  // Function to scramble some letters in the text
  const scrambleText = useCallback((text, amount) => {
    if (!text) return text
    
    const lines = text.split('\n')
    const newLines = lines.map(line => {
      let newLine = ''
      for (let i = 0; i < line.length; i++) {
        // Random chance to change this character based on amount (0-1)
        if (Math.random() < amount) {
          const chars = textOptions.lowResEffect 
            ? '01234567890ABCDEFabcdef-_.|/\\[]{}()<>!@#$%^&*+=:;'
            : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.+='
          newLine += chars.charAt(Math.floor(Math.random() * chars.length))
        } else {
          newLine += line[i]
        }
      }
      return newLine
    })
    
    return newLines.join('\n')
  }, [textOptions.lowResEffect])
  
  // Periodically change letters based on speed
  useFrame((state, delta) => {
    // Skip if speed is 0 (no changes)
    if (textOptions.textChangeSpeed <= 0) return
    
    // Update based on speed
    lastUpdateTimeRef.current += delta * textOptions.textChangeSpeed
    
    // Change text approximately twice per second at speed 1.0
    if (lastUpdateTimeRef.current > 0.5) {
      lastUpdateTimeRef.current = 0
      
      // Scramble the text based on the change amount
      const scrambled = scrambleText(currentText, textOptions.textChangeAmount)
      setCurrentText(scrambled)
    }
  })
  
  // Create texture for the text - recreate when resolution or font size changes
  useEffect(() => {
    if (!currentText) return
    
    // Add FPS to the beginning of the text - make sure fps value is included
    const fpsValue = typeof fps === 'number' ? fps : 0; // Use 0 if fps is undefined
    const textWithFPS = `FPS: ${fpsValue}\n${currentText}`;
    
    const newTexture = createPixelatedTextTexture(textWithFPS, textOptions)
    setTexture(newTexture)
    
    return () => {
      if (newTexture) newTexture.dispose()
    }
  }, [
    currentText,
    fps,
    textOptions.textColor, 
    textOptions.lowResEffect, 
    textOptions.textResolution, 
    textOptions.textFontSize
  ])
  
  // Also update the texture when FPS changes without waiting for text to change
  useEffect(() => {
    if (!currentText) return
    
    // Only update if we have a valid fps value
    if (typeof fps === 'number') {
      const textWithFPS = `FPS: ${fps}\n${currentText}`;
      const newTexture = createPixelatedTextTexture(textWithFPS, textOptions)
      setTexture(newTexture)
      
      return () => {
        if (newTexture) newTexture.dispose()
      }
    }
  }, [fps, currentText, textOptions])
  
  // Offset the position slightly so text doesn't overlap particle
  const textPosition = useMemo(() => {
    return [position[0] + 0.05, position[1] + 0.05, position[2]]
  }, [position])
  
  // Keep the text position updated with the particle
  useFrame(() => {
    if (billboardRef.current) {
      billboardRef.current.position.set(
        position[0] + 0.05,
        position[1] + 0.05,
        position[2]
      )
    }
  })
  
  if (!texture) return null
  
  return (
    <Billboard 
      ref={billboardRef}
      position={textPosition}
      follow={true}
      lockX={false}
      lockY={false}
      lockZ={false}
    >
      <mesh scale={[textOptions.particleTextScale * 0.6, textOptions.particleTextScale * 0.4, 1]}>
        <planeGeometry />
        <meshBasicMaterial 
          map={texture} 
          transparent={true}
          opacity={0.9}
          depthWrite={false}
          side={THREE.DoubleSide}
          alphaTest={0.1} // Use alphaTest to make "almost transparent" pixels fully transparent
        />
      </mesh>
    </Billboard>
  )
}

export function DustParticles() {
  const particlesRef = useRef()
  const linesRef = useRef()
  const [particleTexture, setParticleTexture] = useState(null)
  
  // Line geometry and material refs
  const lineGeometryRef = useRef()
  const lineMaterialRef = useRef()
  
  // Get the dust particle controls
  const controls = useDustParticleControls()
  
  // Log the controls structure for debugging
  console.log("Dust particle controls:", controls);
  
  // Extract properties from controls safely with defaults
  const {
    enabled = true,
    count = 50,
    size = 0.15,
    color = '#ffffff',
    opacity = 0.5,
    speed = 1.5,
    curlScale = 0.5,
    curlIntensity = 1.5,
    respawnPerSecond = 10,
    turbulence = 1.0,
    noiseSpeed = { x: 0.3, y: 0.2, z: 0.25 },
    showBoundingBox = false,
    // Line connections
    enableLines = true,
    maxDistance = 3.0,
    minDistance = 0.5,
    invertDistanceCheck = false,
    lineColor = '#ffffff',
    lineOpacity = 0.3, 
    lineWidth = 1,
    fadeDistance = true,
    maxConnections = 3,
    // Text options
    showParticleText = true,
    textParticleRatio = 0.3,
    particleTextScale = 2.0,
    textResolution = 32,
    textFontSize = 12,
    textColor = '#ffffff',
    textOutlineColor = '#000000',
    lowResEffect = false,
    textChangeSpeed = 10.0,
    textChangeAmount = 0.3,
  } = controls || {}
  
  // Get bounding box directly from controls with robust fallbacks
  const boundingBox = useMemo(() => {
    console.log("Control structure for debugging:", controls);
    
    // Default values for bounding box
    const defaultBox = {
      width: 10,
      height: 10,
      depth: 10,
      position: { x: 0, y: 10, z: 0 }
    };
    
    try {
      // Check if controls has the expected structure
      if (controls?.bounds?.boundingBox?.value) {
        return {
          width: controls.bounds.boundingBox.value.width || defaultBox.width,
          height: controls.bounds.boundingBox.value.height || defaultBox.height,
          depth: controls.bounds.boundingBox.value.depth || defaultBox.depth,
          position: {
            x: controls.bounds.boundingBox.value.position?.x || defaultBox.position.x,
            y: controls.bounds.boundingBox.value.position?.y || defaultBox.position.y,
            z: controls.bounds.boundingBox.value.position?.z || defaultBox.position.z
          }
        };
      }
    } catch (err) {
      console.error("Error accessing bounding box:", err);
    }
    
    console.warn("Bounding box control structure not found, using defaults");
    return defaultBox;
  }, [controls]);
  
  // Group text options for passing to components
  const textOptions = useMemo(() => ({
    showParticleText,
    textParticleRatio,
    particleTextScale,
    textColor,
    textOutlineColor,
    lowResEffect,
    textResolution,
    textFontSize,
    textChangeSpeed,
    textChangeAmount,
  }), [
    showParticleText,
    textParticleRatio,
    particleTextScale,
    textColor,
    textOutlineColor,
    lowResEffect,
    textResolution,
    textFontSize,
    textChangeSpeed,
    textChangeAmount,
  ])
  
  // Animation time
  const timeRef = useRef(0)
  
  // Create particle texture
  useEffect(() => {
    // Create a canvas for the particle texture
    const canvas = document.createElement('canvas')
    canvas.width = 64
    canvas.height = 64
    const ctx = canvas.getContext('2d')
    
    // Clear canvas
    ctx.clearRect(0, 0, 64, 64)
    
    // Create a radial gradient
    const gradient = ctx.createRadialGradient(32, 32, 0, 32, 32, 32)
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)')
    gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.5)')
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')
    
    // Fill with gradient
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, 64, 64)
    
    // Create texture from canvas
    const texture = new THREE.CanvasTexture(canvas)
    texture.needsUpdate = true
    setParticleTexture(texture)
    
    // Create line material
    lineMaterialRef.current = new THREE.LineBasicMaterial({
      color: new THREE.Color(lineColor),
      transparent: true,
      opacity: lineOpacity,
      linewidth: lineWidth,
      vertexColors: fadeDistance, // Use vertex colors for fading
      blending: THREE.AdditiveBlending
    })
    
    // Create line geometry (initially empty)
    lineGeometryRef.current = new THREE.BufferGeometry()
    
    return () => {
      // Clean up resources
      if (lineGeometryRef.current) lineGeometryRef.current.dispose()
      if (lineMaterialRef.current) lineMaterialRef.current.dispose()
      if (texture) texture.dispose()
    }
  }, [lineColor, lineOpacity, lineWidth, fadeDistance])
  
  // Create particles with initial random positions
  const particles = useMemo(() => {
    const temp = []
    
    // Create initial particles
    for (let i = 0; i < count; i++) {
      // Random position within bounding box
      const x = (Math.random() - 0.5) * boundingBox.width
      const y = (Math.random() - 0.5) * boundingBox.height
      const z = (Math.random() - 0.5) * boundingBox.depth
      
      // Random scale variation for particles
      const scale = size * (0.5 + Math.random() * 0.5)
      
      // Random rotation
      const rotation = Math.random() * Math.PI * 2
      
      // Determine if this particle should have text
      const hasText = Math.random() < textParticleRatio
      
      // Add particle data
      temp.push({
        position: [x, y, z],
        scale,
        rotation,
        // Add more significant initial velocities
        velocity: [
          (Math.random() - 0.5) * 0.2,
          (Math.random() - 0.5) * 0.2,
          (Math.random() - 0.5) * 0.2
        ],
        // Random acceleration factors
        acceleration: [
          Math.random() * 0.5 + 0.5,
          Math.random() * 0.5 + 0.5, 
          Math.random() * 0.5 + 0.5
        ],
        // Life duration
        life: Math.random() * 10,
        // Store original position for vortex effects
        originalPos: [x, y, z],
        // Connection tracking
        connections: [],
        // Text flag
        hasText
      })
    }
    
    return temp
  }, [count, size, boundingBox, textParticleRatio])
  
  // For respawning particles
  const respawnCounter = useRef(0)
  
  // Better curl noise calculation
  const curlNoise = (x, y, z, time) => {
    const eps = 0.0001
    
    // Simple curl noise implementation
    const curl = new THREE.Vector3()
    
    // Add time to make the noise evolve - slower evolution
    const tx = x + time * noiseSpeed.x * 0.3
    const ty = y + time * noiseSpeed.y * 0.3
    const tz = z + time * noiseSpeed.z * 0.3
    
    // Get noise at sample points with time evolution
    const n1 = noise(tx + eps, ty, tz, time) - noise(tx - eps, ty, tz, time)
    const n2 = noise(tx, ty + eps, tz, time) - noise(tx, ty - eps, tz, time)
    const n3 = noise(tx, ty, tz + eps, time) - noise(tx, ty, tz - eps, time)
    
    // Calculate curl with gentler intensity
    curl.x = (n2 - n3) * curlIntensity * 0.3
    curl.y = (n3 - n1) * curlIntensity * 0.3
    curl.z = (n1 - n2) * curlIntensity * 0.3
    
    return curl
  }
  
  // Improved noise function with time parameter
  const noise = (x, y, z, time) => {
    // Scale the coordinates
    x *= curlScale
    y *= curlScale
    z *= curlScale
    
    // Add some turbulence and time variation
    const t = time * 0.2
    
    // More complex noise using multiple frequencies
    return (
      Math.sin(x + t) * Math.cos(y * 1.5) * Math.sin(z) * 0.5 +
      Math.sin(x * 2 + t * 1.5) * Math.cos(y * 0.8) * Math.sin(z * 1.3) * 0.25 +
      Math.sin(x * 4 + t * 0.3) * Math.cos(y * 2.2) * Math.sin(z * 0.8) * 0.125
    ) * turbulence
  }
  
  // Animation loop
  useFrame((state, delta) => {
    if (!particlesRef.current || !enabled) return
    
    // Update time
    timeRef.current += delta
    const time = timeRef.current
    
    // Update particle positions using velocity and curl noise
    const instances = particlesRef.current.children
    
    // Add to respawn counter
    respawnCounter.current += delta * respawnPerSecond
    let particlesToRespawn = Math.floor(respawnCounter.current)
    respawnCounter.current -= particlesToRespawn
    
    // Keep track of which instances are sprites and update only those
    let spriteIndex = 0
    
    // Update particles
    instances.forEach((instance) => {
      // Skip non-sprites (like text elements)
      if (!instance.isSprite) return
      
      // Get the current particle data
      const particle = particles[spriteIndex]
      
      // Make sure particle exists
      if (!particle) {
        console.warn('Particle not found for instance at index', spriteIndex)
        spriteIndex++
        return
      }
      
      // Update life
      particle.life -= delta
      
      // If particle should be respawned
      if (particle.life <= 0 || (particlesToRespawn > 0 && Math.random() < 0.05)) {
        // Respawn the particle
        particle.position[0] = (Math.random() - 0.5) * boundingBox.width
        particle.position[1] = (Math.random() - 0.5) * boundingBox.height
        particle.position[2] = (Math.random() - 0.5) * boundingBox.depth
        particle.life = 5 + Math.random() * 5
        
        // Reset velocity with more energy
        particle.velocity[0] = (Math.random() - 0.5) * 0.2
        particle.velocity[1] = (Math.random() - 0.5) * 0.2
        particle.velocity[2] = (Math.random() - 0.5) * 0.2
        
        // Store new original position
        particle.originalPos = [...particle.position]
        
        // Reset connections
        particle.connections = []
        
        // Determine if this particle should have text (recalculate on respawn)
        particle.hasText = Math.random() < textParticleRatio
        
        particlesToRespawn--
      }
      
      // Get curl noise based on current position and time
      const curl = curlNoise(
        particle.position[0],
        particle.position[1],
        particle.position[2],
        time
      )
      
      // Update velocity with curl force
      particle.velocity[0] += curl.x * particle.acceleration[0] * 0.5
      particle.velocity[1] += curl.y * particle.acceleration[1] * 0.5
      particle.velocity[2] += curl.z * particle.acceleration[2] * 0.5
      
      // Add small random movement for more natural behavior
      particle.velocity[0] += (Math.random() - 0.5) * 0.01
      particle.velocity[1] += (Math.random() - 0.5) * 0.01
      particle.velocity[2] += (Math.random() - 0.5) * 0.01
      
      // Update position with velocity and delta time
      particle.position[0] += particle.velocity[0] * speed * delta * 10
      particle.position[1] += particle.velocity[1] * speed * delta * 10
      particle.position[2] += particle.velocity[2] * speed * delta * 10
      
      // Contain particles within bounds with bouncing
      for (let axis = 0; axis < 3; axis++) {
        const limit = axis === 0 ? boundingBox.width/2 : (axis === 1 ? boundingBox.height/2 : boundingBox.depth/2)
        if (Math.abs(particle.position[axis]) > limit) {
          particle.position[axis] = Math.sign(particle.position[axis]) * limit
          particle.velocity[axis] *= -0.8 // Bounce with energy loss
        }
      }
      
      // Apply slight damping to avoid perpetual acceleration
      particle.velocity[0] *= 0.99
      particle.velocity[1] *= 0.99
      particle.velocity[2] *= 0.99
      
      // Update the instance position with bounding box offset
      instance.position.set(
        particle.position[0] + boundingBox.position.x,
        particle.position[1] + boundingBox.position.y,
        particle.position[2] + boundingBox.position.z
      )
      
      // Slowly rotate the particle
      particle.rotation += delta * 0.5
      instance.rotation.z = particle.rotation
      
      // Update scale with slight pulsing based on position and time
      const pulseScale = 1 + Math.sin(time * 2 + particle.position[0] + particle.position[2]) * 0.1
      instance.scale.set(
        particle.scale * pulseScale,
        particle.scale * pulseScale, 
        particle.scale * pulseScale
      )
      
      // Reset connections for this particle
      particle.connections = []
      
      // Increment sprite counter since we processed a sprite
      spriteIndex++
    })
    
    // If lines are enabled, update connections
    if (enableLines && lineGeometryRef.current && lineMaterialRef.current) {
      // Update line material properties
      lineMaterialRef.current.color = new THREE.Color(lineColor)
      lineMaterialRef.current.opacity = lineOpacity
      lineMaterialRef.current.linewidth = lineWidth
      lineMaterialRef.current.vertexColors = fadeDistance
      
      // Arrays to store line vertices and optional colors
      const linePositions = []
      const lineColors = []
      
      // Find connections between particles
      for (let i = 0; i < particles.length; i++) {
        const p1 = particles[i]
        
        // Limit the number of connections per particle to improve performance
        if (p1.connections.length >= maxConnections) continue
        
        for (let j = i + 1; j < particles.length; j++) {
          const p2 = particles[j]
          
          // Skip if p2 already has max connections
          if (p2.connections.length >= maxConnections) continue
          
          // Calculate distance between particles
          const dx = p1.position[0] - p2.position[0]
          const dy = p1.position[1] - p2.position[1]
          const dz = p1.position[2] - p2.position[2]
          const distance = Math.sqrt(dx*dx + dy*dy + dz*dz)
          
          // Check if particles should connect based on distance logic
          let shouldConnect = false;
          
          if (invertDistanceCheck) {
            // Inverted logic: prefer long lines - connect if distance is between min and max
            shouldConnect = distance >= minDistance && distance <= maxDistance;
          } else {
            // Normal logic: prefer short lines - connect if distance is less than max
            shouldConnect = distance <= maxDistance && distance >= minDistance;
          }
          
          // If particles should connect, draw a line between them
          if (shouldConnect) {
            // Add connection for both particles
            p1.connections.push(j)
            p2.connections.push(i)
            
            // Add line vertices with bounding box offset
            linePositions.push(
              p1.position[0] + boundingBox.position.x, p1.position[1] + boundingBox.position.y, p1.position[2] + boundingBox.position.z,
              p2.position[0] + boundingBox.position.x, p2.position[1] + boundingBox.position.y, p2.position[2] + boundingBox.position.z
            )
            
            // Add vertex colors if fading with distance
            if (fadeDistance) {
              // Calculate opacity based on distance
              let opacity;
              
              if (invertDistanceCheck) {
                // For inverted logic, shorter lines are more transparent
                opacity = (distance - minDistance) / (maxDistance - minDistance);
              } else {
                // For normal logic, longer lines are more transparent
                opacity = 1 - ((distance - minDistance) / (maxDistance - minDistance));
              }
              
              const colorObj = new THREE.Color(lineColor)
              
              // Push the same color twice (for each end of the line)
              lineColors.push(
                colorObj.r, colorObj.g, colorObj.b, opacity,
                colorObj.r, colorObj.g, colorObj.b, opacity
              )
            }
            
            // Break if max connections reached
            if (p1.connections.length >= maxConnections) break
          }
        }
      }
      
      // Update line geometry
      if (linePositions.length > 0) {
        // Set positions
        lineGeometryRef.current.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(linePositions, 3)
        )
        
        // Set colors if fading with distance
        if (fadeDistance && lineColors.length > 0) {
          lineGeometryRef.current.setAttribute(
            'color', 
            new THREE.Float32BufferAttribute(lineColors, 4)
          )
        }
      }
    }
  })
  
  // Handle bounding box changes
  const handleBoundingBoxChange = useCallback((newBoundingBox) => {
    try {
      // Only update if the controls structure exists
      if (controls?.bounds?.boundingBox?.value) {
        console.log("Updating bounding box to:", newBoundingBox);
        controls.bounds.boundingBox.value.width = newBoundingBox.width;
        controls.bounds.boundingBox.value.height = newBoundingBox.height;
        controls.bounds.boundingBox.value.depth = newBoundingBox.depth;
        
        // Update position if provided
        if (newBoundingBox.position) {
          if (!controls.bounds.boundingBox.value.position) {
            controls.bounds.boundingBox.value.position = {};
          }
          controls.bounds.boundingBox.value.position.x = newBoundingBox.position.x;
          controls.bounds.boundingBox.value.position.y = newBoundingBox.position.y;
          controls.bounds.boundingBox.value.position.z = newBoundingBox.position.z;
        }
      } else {
        console.warn("Cannot update bounding box: control structure not found");
      }
    } catch (err) {
      console.error("Error updating bounding box:", err);
    }
  }, [controls]);
  
  // Check if particles should be rendered
  const renderParticles = enabled && particleTexture;
  
  return (
    <group>
      {/* Bounding box visualization - always render regardless of particles */}
      <ParticleBoundingBox 
        boundingBox={boundingBox} 
        onChange={handleBoundingBoxChange} 
        visible={showBoundingBox}
      />
      
      {/* Particle system - only render if enabled and texture is loaded */}
      {renderParticles && (
        <>
          {/* Render particles */}
          <group ref={particlesRef}>
            {particles.map((particle, i) => (
              <React.Fragment key={i}>
                <sprite 
                  position={particle.position}
                  scale={particle.scale}
                  rotation-z={particle.rotation}
                >
                  <spriteMaterial
                    map={particleTexture}
                    transparent={true}
                    opacity={opacity}
                    color={color}
                    depthWrite={false}
                    blending={THREE.AdditiveBlending}
                  />
                </sprite>
                
                {/* Add text next to particle if enabled */}
                {showParticleText && particle.hasText && (
                  <ParticleText 
                    position={particle.position} 
                    textOptions={textOptions}
                    particleIndex={i}
                  />
                )}
              </React.Fragment>
            ))}
          </group>
          
          {/* Render connections */}
          {enableLines && (
            <lineSegments ref={linesRef}>
              <primitive object={lineGeometryRef.current} attach="geometry" />
              <primitive object={lineMaterialRef.current} attach="material" />
            </lineSegments>
          )}
        </>
      )}
    </group>
  )
} 