import { useControls, folder } from 'leva'

export function useDustParticleControls() {
  return useControls('Dust Particles', {
    enabled: { value: true, label: "Show Dust Particles" },
    count: { value: 50, min: 50, max: 1000, step: 10 },
    size: { value: 0.15, min: 0.05, max: 1, step: 0.01 },
    color: { value: '#ffffff' },
    opacity: { value: 0.5, min: 0.1, max: 1, step: 0.05 },
    speed: { value: 1.5, min: 0.1, max: 5, step: 0.1 },
    // Show bounding box control
    showBoundingBox: { value: false, label: "Show Bounding Box" },
    
    // Curl noise settings
    curlNoise: folder({
      curlScale: { value: 0.5, min: 0.1, max: 2, step: 0.05 },
      curlIntensity: { value: 1.5, min: 0.1, max: 5, step: 0.1 },
      turbulence: { value: 1.0, min: 0.1, max: 3.0, step: 0.1 },
      noiseSpeed: { 
        value: { x: 0.3, y: 0.2, z: 0.25 },
        x: { min: 0.01, max: 1.0, step: 0.01 },
        y: { min: 0.01, max: 1.0, step: 0.01 },
        z: { min: 0.01, max: 1.0, step: 0.01 }
      }
    }),
    
    // Text settings
    textOptions: folder({
      showParticleText: { value: true, label: "Show Text Labels" },
      textParticleRatio: { value: 0.3, min: 0.05, max: 1.0, step: 0.05, label: "Particles with Text %" },
      particleTextScale: { value: 2.0, min: 0.2, max: 10.0, step: 0.1, label: "Text Size" },
      textResolution: { value: 128, min: 32, max: 1024, step: 32, label: "Text Resolution" },
      textFontSize: { value: 10, min: 6, max: 24, step: 1, label: "Font Size" },
      textColor: { value: '#ffffff', label: "Text Color" },
      textOutlineColor: { value: '#000000', label: "Text Outline Color" },
      lowResEffect: { value: false, label: "Low-Res Text Effect" },
      textChangeSpeed: { value: 10.0, min: 0, max: 10, step: 0.1, label: "Text Change Speed" },
      textChangeAmount: { value: 0.3, min: 0, max: 1.0, step: 0.05, label: "Text Change Amount" }
    }),
    
    // Line connection settings
    connections: folder({
      enableLines: { value: true, label: "Connect Particles" },
      maxDistance: { value: 3.0, min: 0.5, max: 10.0, step: 0.1, label: "Max Connection Distance" },
      minDistance: { value: 0.5, min: 0.1, max: 5.0, step: 0.1, label: "Min Connection Distance" },
      invertDistanceCheck: { value: false, label: "Prefer Long Lines" },
      lineColor: { value: '#ffffff', label: "Line Color" },
      lineOpacity: { value: 0.3, min: 0.05, max: 1.0, step: 0.05, label: "Line Opacity" },
      lineWidth: { value: 1, min: 0.1, max: 5.0, step: 0.1, label: "Line Width" },
      fadeDistance: { value: true, label: "Fade Lines With Distance" },
      maxConnections: { value: 3, min: 1, max: 10, step: 1, label: "Max Connections Per Particle" }
    }),
    
    // Bounding box for particles
    bounds: folder({
      boundingBox: {
        value: { 
          width: 1, 
          height: 1, 
          depth: 1,
          position: { x: 0, y: 0, z: 10 }
        }
      }
    }),
    
    // Particle lifecycle
    lifecycle: folder({
      respawnPerSecond: { value: 10, min: 0, max: 50, step: 1 }
    })
  }, { collapsed: true })
} 