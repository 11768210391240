import { useState, useEffect, createContext, useContext } from 'react'
import { useFrame } from '@react-three/fiber'

// Create a context to share FPS data
const FPSContext = createContext({ fps: 0, setFPS: () => {} })

// Export the context and a hook to access FPS
export const useFPS = () => useContext(FPSContext)
export { FPSContext }

// This component goes inside the Canvas
export function FPSCalculator() {
  // FPS calculation variables
  const frameCount = { current: 0 }
  const lastTime = { current: performance.now() }
  const updateInterval = 500 // Update FPS display every 500ms
  const { setFPS } = useContext(FPSContext)
  
  // Calculate FPS using useFrame hook
  useFrame(() => {
    // Increment frame count
    frameCount.current++
    
    // Check if it's time to update the FPS counter
    const currentTime = performance.now()
    const elapsedTime = currentTime - lastTime.current
    
    if (elapsedTime >= updateInterval) {
      // Calculate FPS: frames / seconds
      const calculatedFPS = Math.round((frameCount.current * 1000) / elapsedTime)
      setFPS(calculatedFPS)
      
      // Reset counters
      frameCount.current = 0
      lastTime.current = currentTime
    }
  })
  
  return null // This component doesn't render anything
}

// Display component that shows the FPS value
export function FPSDisplay() {
  const { fps } = useContext(FPSContext)
  
  return (
    <div style={{
      position: 'fixed',
      top: '10px',
      right: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '8px 12px',
      borderRadius: '4px',
      fontSize: '14px',
      fontFamily: 'monospace',
      zIndex: 1000,
      userSelect: 'none'
    }}>
      {fps} FPS
    </div>
  )
}

// Provider component that wraps everything
export function FPSCounter({ children }) {
  const [fps, setFPS] = useState(0)
  
  return (
    <FPSContext.Provider value={{ fps, setFPS }}>
      {children}
      <FPSDisplay />
    </FPSContext.Provider>
  )
} 